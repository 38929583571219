import P2Medium from 'components/typography/P2Medium';
import { SystemExportRow } from 'components/tables/data/system-export/types';
import { Group } from '@mantine/core';
import { IconUser } from '@tabler/icons-react';

/**
 * Column for the author name
 */
export default function AuthorNameColumn({ data: { author } }: { data: SystemExportRow }) {
  return (
    <Group spacing={8}>
      <IconUser size={24} />
      <P2Medium color="gray.8">{author.fullName}</P2Medium>
    </Group>
  );
}
