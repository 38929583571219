import { useApi } from 'api/api-context';
import SystemExportTable from 'components/tables/data/system-export/SystemExportTable';
import DashboardLayout from 'layouts/dashboard/DashboardLayout';
import { ROLE_ADMIN_ID } from 'model/Role';
import { Navigate } from 'react-router-dom';
import { DASHBOARD_PAGE_PATH, EXPORT_PAGE_PATH, SETTINGS_PAGE_PATH } from 'routes/paths';

/**
 * Page where the user can export data from the system.
 */
export default function SystemExportPage() {
  const { roleId } = useApi();

  if (roleId !== ROLE_ADMIN_ID) {
    return <Navigate to={DASHBOARD_PAGE_PATH.original} replace />;
  }

  return (
    <DashboardLayout
      breadcrumbs={[
        { title: 'Nastavenia', link: SETTINGS_PAGE_PATH.original },
        { title: 'Systémový export', link: EXPORT_PAGE_PATH.original },
      ]}
      height="screen"
    >
      <SystemExportTable />
    </DashboardLayout>
  );
}
