import NavbarItem from 'layouts/dashboard/navbar/NavbarItem';
import { NavLink, Stack } from '@mantine/core';
import {
  IconAlarmPlus,
  IconAlertTriangle,
  IconBuilding,
  IconLayoutDashboard,
  IconLicense,
  IconMapPins,
  IconServer,
  IconSettings,
  IconUser,
} from '@tabler/icons-react';
import {
  DASHBOARD_PAGE_PATH,
  DEPARTMENTS_PAGE_PATH,
  DEVICES_PAGE_PATH,
  DEVICE_REVISION_PLANS_PAGE_PATH,
  DEVICE_TYPES_PAGE_PATH,
  EXPORT_PAGE_PATH,
  FAULTS_PAGE_PATH,
  GLOBAL_PREDEFINED_DATA_PAGE_PATH,
  MEASURING_DEVICES_PAGE_PATH,
  MY_COMPANY_SETTINGS_PAGE_PATH,
  ORGANIZATIONS_PAGE_PATH,
  REVISIONS_PAGE_PATH,
  REVISION_TEMPLATES_PAGE_PATH,
  SETTINGS_PAGE_PATH,
  USERS_PAGE_PATH,
  USERS_PREDEFINED_DATA_PAGE_PATH,
} from 'routes/paths';
import useLocalStorage from 'hooks/use-local-storage';
import { useApi } from 'api/api-context';
import {
  FEATURE_TOGGLE_BROWSE_REVISION_TEMPLATES,
  FEATURE_TOGGLE_CREATE_DEVICE_SUBTYPES,
  FEATURE_TOGGLE_EXPORT_PAGE,
} from 'env';
import { ROLE_ADMIN_ID } from 'model/Role';

/**
 * The navigation menu inside the navbar.
 */
export default function NavbarMenu({ isCollapsed }: { isCollapsed: boolean }) {
  const [opened, setOpened] = useLocalStorage('fmpoint.navbar.menu', { settings: false });
  const { hasPermissionAnywhere, roleId } = useApi();

  return (
    <Stack
      spacing={12}
      align={isCollapsed ? 'flex-start' : 'initial'}
      sx={{
        width: isCollapsed ? 'fit-content' : 'initial',
        marginLeft: isCollapsed ? 'auto' : 'inherit',
        marginRight: isCollapsed ? 'auto' : 'inherit',
      }}
    >
      {/* Dashboard */}
      <NavbarItem
        to={DASHBOARD_PAGE_PATH.original}
        icon={<IconLayoutDashboard stroke="1.5" />}
        label="Dashboard"
        isCollapsed={isCollapsed}
      />

      {hasPermissionAnywhere('browse-devices') && (
        <NavbarItem
          to={DEVICES_PAGE_PATH.original}
          icon={<IconServer stroke="1.5" />}
          label="Zariadenia"
          isCollapsed={isCollapsed}
        />
      )}

      {/* Revízne správy */}
      {hasPermissionAnywhere('*-revisions') && (
        <NavbarItem
          to={REVISIONS_PAGE_PATH.original}
          icon={<IconLicense stroke="1.5" />}
          label="Revízne správy"
          isCollapsed={isCollapsed}
        />
      )}

      {hasPermissionAnywhere('browse-devices') && (
        <NavbarItem
          to={DEVICE_REVISION_PLANS_PAGE_PATH.insert({ tabName: 'table' })}
          icon={<IconAlarmPlus stroke="1.5" />}
          label="Sledovanie termínov"
          isCollapsed={isCollapsed}
        />
      )}

      {hasPermissionAnywhere('browse-devices') && (
        <NavbarItem
          to={FAULTS_PAGE_PATH.original}
          icon={<IconAlertTriangle stroke="1.5" />}
          label="Závady"
          isCollapsed={isCollapsed}
        />
      )}

      {/* Organizácie */}
      {hasPermissionAnywhere('manage-organizations') && (
        <NavbarItem
          to={ORGANIZATIONS_PAGE_PATH.original}
          icon={<IconBuilding stroke="1.5" />}
          label="Organizácie"
          isCollapsed={isCollapsed}
        />
      )}

      {hasPermissionAnywhere('manage-departments') && (
        <NavbarItem
          to={DEPARTMENTS_PAGE_PATH.original}
          icon={<IconMapPins stroke="1.5" />}
          label="Strediská"
          isCollapsed={isCollapsed}
        />
      )}

      {/* Users */}
      {hasPermissionAnywhere('manage-users') && (
        <NavbarItem
          to={USERS_PAGE_PATH.original}
          icon={<IconUser stroke="1.5" />}
          label="Používatelia"
          isCollapsed={isCollapsed}
        />
      )}

      {/* Nastavenia */}
      {isCollapsed ? (
        <NavbarItem
          to={SETTINGS_PAGE_PATH.original}
          icon={<IconSettings stroke="1.5" />}
          isCollapsed={isCollapsed}
          label="Nastavenia"
        />
      ) : (
        <NavLink
          opened={opened.settings}
          onChange={(settings) => setOpened({ ...opened, settings })}
          icon={<IconSettings stroke="1.5" />}
          label="Nastavenia"
        >
          <Stack spacing={4}>
            {hasPermissionAnywhere('fill-out-revisions') && (
              <NavbarItem to={MY_COMPANY_SETTINGS_PAGE_PATH.original} label="Nastavenia spoločnosti" />
            )}
            {hasPermissionAnywhere('manage-global-predefined-data') && (
              <NavbarItem to={GLOBAL_PREDEFINED_DATA_PAGE_PATH.original} label="Globálne predvol. údaje" />
            )}
            <NavbarItem to={USERS_PREDEFINED_DATA_PAGE_PATH.original} label="Predvolené údaje" />
            {hasPermissionAnywhere('fill-out-revisions') && (
              <NavbarItem to={MEASURING_DEVICES_PAGE_PATH.original} label="Meracie prístroje" />
            )}
            {FEATURE_TOGGLE_CREATE_DEVICE_SUBTYPES && hasPermissionAnywhere('define-device-subtypes') && (
              <NavbarItem to={DEVICE_TYPES_PAGE_PATH.original} label="Typy zariadení" />
            )}
            {FEATURE_TOGGLE_BROWSE_REVISION_TEMPLATES && roleId === ROLE_ADMIN_ID && (
              <NavbarItem to={REVISION_TEMPLATES_PAGE_PATH.original} label="Šablóny revíznych správ" />
            )}
            {FEATURE_TOGGLE_EXPORT_PAGE && roleId === ROLE_ADMIN_ID && (
              <NavbarItem to={EXPORT_PAGE_PATH.original} label="Systémový export" />
            )}
          </Stack>
        </NavLink>
      )}
    </Stack>
  );
}
