import P2Medium from 'components/typography/P2Medium';
import { SystemExportRow } from 'components/tables/data/system-export/types';
import { Group } from '@mantine/core';
import FileTypeIcon from 'components/files/upload/FileTypeIcon';

/**
 * Column for the file name
 */
export default function FileNameColumn({ data: { fileName } }: { data: SystemExportRow }) {
  return (
    <Group spacing={8} bg="blue.0" py={4} px={12} className="rounded-md">
      <FileTypeIcon type="application/zip" />
      <P2Medium color="gray.8">{fileName}</P2Medium>
    </Group>
  );
}
