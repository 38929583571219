import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { SystemExportListPathParameters } from 'api/actions/system-export-list/system-export-list-path-parameters';
import { SystemExportListResponse } from 'api/actions/system-export-list/system-export-list-response';

type SystemExportListQueryParameters = Record<string, any>;

/**
 * The path of the SystemExportList action.
 */
export const systemExportListActionPath = new SweetPath<keyof SystemExportListPathParameters>(
  '/api/system/export/list',
  { path: '{param}' }
);

/**
 * Creates a contextualized SystemExportList actions.
 */
export default function createSystemExportListAction(axios: AxiosInstance) {
  return function systemExportListAction({
    headers = {},
    query = {},
  }: { headers?: AxiosRequestConfig<any>['headers']; query?: SystemExportListQueryParameters } = {}) {
    const path = systemExportListActionPath.original;

    return axios.get<SystemExportListResponse, SystemExportListResponse>(path, { params: query, headers });
  };
}
