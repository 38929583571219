import { GridApi } from 'ag-grid-community';
import { SystemExportRow } from 'components/tables/data/system-export/types';
import SystemExportActions from 'components/tables/data/system-export/SystemExportActions';

/**
 * Column for the actions.
 */
export default function ActionsColumn({ api, data }: { api: GridApi<SystemExportRow>; data: SystemExportRow }) {
  return <SystemExportActions {...data} onDelete={() => api.applyTransaction({ remove: [data] })} />;
}
