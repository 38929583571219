import { Button, Group, Input, Modal, Stack } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import Toast from 'components/Toast';
import { FEATURE_TOGGLE_TECHNICIAN_COPY_REVISION_DATA } from 'env';
import { useEffect, useState } from 'react';
import { useFillOutRevisionDataProvider } from 'components/forms/revision/fill-out/data/FillOutRevisionDataProvider';
import { useFillOutRevisionSaveDraftProvider } from 'components/forms/revision/fill-out/data/FillOutRevisionSaveDraftProvider';
import { RevisionGetResponse } from 'api/actions/revision-get/revision-get-response';
import { useApi } from 'api/api-context';
import panic from 'errors/panic';
import RevisionSelectWithPreview from 'components/forms/revision/fill-out/modals/copy-past-revision-modal/RevisionSelectWithPreview';
import RevisionDetailsBox from 'components/forms/revision/fill-out/modals/copy-past-revision-modal/RevisionDetailsBox';
import sleep from 'utils/sleep';
import { copyRevisionData } from 'pages/revisions-module/copy-revision/CopyRevisionPage';
import { merge } from 'lodash';

/**
 * Modal used to copy data from another revision made by the same technician.
 */
export default function CopyPastRevisionModal() {
  const { getAction } = useApi();
  const { revision, form } = useFillOutRevisionDataProvider();
  const { saveDraft } = useFillOutRevisionSaveDraftProvider();

  const [opened, { open, close }] = useDisclosure(false);
  const [selectedRevisionId, setSelectedRevisionId] = useState<number | null>(null);
  const [selectedRevision, setSelectedRevision] = useState<RevisionGetResponse | null>(null);
  const [fetchingRevision, { open: startFetchingRevision, close: stopFetchingRevision }] = useDisclosure(false);
  const [copyingData, { open: startCopyingData, close: stopCopyingData }] = useDisclosure(false);

  /** Copy data from selected revision to the current form. */
  const copyData = async () => {
    try {
      if (copyingData) {
        return;
      }

      if (!selectedRevision) {
        throw new Error('Selected revision is not loaded.'); // This should never happen.
      }

      if (!selectedRevision.revisionData) {
        throw new Error('Selected revision does not contain revision data.'); // This should never happen.
      }

      startCopyingData();

      const copiedData = copyRevisionData({
        oldRevision: selectedRevision,
        newDeviceIds: revision.devices.map(({ deviceId }) => deviceId),
      });

      const newData = merge({}, form.values, copiedData);

      await saveDraft({ notify: false, overrideData: newData });
      form.setValues(newData);
      await sleep(200);

      close();
    } catch (error: any) {
      panic(error);
    } finally {
      stopCopyingData();
    }
  };

  /** Start with empty revision. */
  const startEmptyRevision = () => {
    saveDraft({ notify: false });
    close();
  };

  // Open modal if revision is empty.
  useEffect(() => {
    if (!revision.hasRevisionData) {
      if (FEATURE_TOGGLE_TECHNICIAN_COPY_REVISION_DATA) {
        open();
      } else {
        saveDraft({ notify: false });
      }
    }
  }, [revision.hasRevisionData]);

  // Fetch selected revision.
  useEffect(() => {
    if (selectedRevisionId) {
      const fetchRevision = getAction('RevisionGet');

      startFetchingRevision();

      fetchRevision({ parameters: { revisionId: String(selectedRevisionId) } })
        .then(setSelectedRevision)
        .catch(panic)
        .finally(stopFetchingRevision);
    }
  }, [selectedRevisionId]);

  return (
    <Modal
      opened={opened}
      onClose={close}
      title="Kopírovanie dát z inej revíznej správy"
      withCloseButton={false}
      closeOnClickOutside={false}
      closeOnEscape={false}
      size={650}
    >
      <Stack spacing={24}>
        <Input mt={-24} type="hidden" data-autofocus />
        <Toast
          type="info"
          message="Do prázdnej revíznej správy môžete nakopírovať dáta z inej revíznej správy. Ak chcete začať s prázdnou revíznou správou, kliknite na tlačidlo Nechcem kopírovať dáta."
          withCloseButton={false}
        />
        <RevisionSelectWithPreview value={selectedRevisionId} onChange={setSelectedRevisionId} />
        {selectedRevisionId && <RevisionDetailsBox revision={selectedRevision} loading={fetchingRevision} />}
        <Group position="right">
          <Button variant="subtle-gray" onClick={startEmptyRevision} disabled={copyingData}>
            Nechcem kopírovať dáta
          </Button>
          <Button variant="primary" disabled={!selectedRevision} onClick={copyData} loading={copyingData}>
            Kopírovať dáta
          </Button>
        </Group>
      </Stack>
    </Modal>
  );
}
