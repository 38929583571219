import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { DeviceListAllEvidenceFieldsPathParameters } from 'api/actions/device-list-all-evidence-fields/device-list-all-evidence-fields-path-parameters';
import { DeviceListAllEvidenceFieldsResponse } from 'api/actions/device-list-all-evidence-fields/device-list-all-evidence-fields-response';

type DeviceListAllEvidenceFieldsQueryParameters = Record<string, any>;

/**
 * The path of the DeviceListAllEvidenceFields action.
 */
export const deviceListAllEvidenceFieldsActionPath = new SweetPath<keyof DeviceListAllEvidenceFieldsPathParameters>(
  '/api/device/evidence-fields',
  { path: '{param}' }
);

/**
 * Creates a contextualized DeviceListAllEvidenceFields actions.
 */
export default function createDeviceListAllEvidenceFieldsAction(axios: AxiosInstance) {
  return function deviceListAllEvidenceFieldsAction({
    headers = {},
    query = {},
  }: { headers?: AxiosRequestConfig<any>['headers']; query?: DeviceListAllEvidenceFieldsQueryParameters } = {}) {
    const path = deviceListAllEvidenceFieldsActionPath.original;

    return axios.get<DeviceListAllEvidenceFieldsResponse, DeviceListAllEvidenceFieldsResponse>(path, {
      params: query,
      headers,
    });
  };
}
