import { Group } from '@mantine/core';
import { IconBorderAll, IconCalendar } from '@tabler/icons-react';
import RevisionPlanCalendar from 'components/tables/data/revision-plan/RevisionPlanCalendar';
import RevisionPlanTable from 'components/tables/data/revision-plan/RevisionPlanTable';
import P2Medium from 'components/typography/P2Medium';
import DashboardLayout from 'layouts/dashboard/DashboardLayout';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

/**
 * Component allowing the user to switch between calendar and table view.
 */
function TabBox({
  text,
  isActive,
  icon,
  onClick,
}: {
  text: string;
  isActive?: boolean;
  icon: JSX.Element;
  onClick: () => void;
}) {
  return (
    <Group
      py={8}
      px={16}
      spacing={8}
      onClick={onClick}
      sx={() => ({
        cursor: 'pointer',
        borderRadius: '4px',
        backgroundColor: isActive ? 'white' : '',
        ':hover': {
          backgroundColor: 'white',
        },
      })}
    >
      {icon}
      <P2Medium color="gray.8">{text}</P2Medium>
    </Group>
  );
}

/**
 * Page for displaying revisions plans - table or calendar.
 */
export default function RevisionPlansPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [tab, setTab] = useState(() => searchParams.get('tab') ?? 'table');

  const [initialFilters] = useState(() => {
    const filters: Record<string, any> = {};

    if (searchParams.has('organizationId')) {
      filters['organization.organizationId'] = {
        filterType: 'text',
        type: 'equals',
        filter: searchParams.get('organizationId'),
      };
    }

    if (searchParams.has('departmentId')) {
      filters['department.departmentId'] = {
        filterType: 'text',
        type: 'equals',
        filter: searchParams.get('departmentId'),
      };
    }

    if (searchParams.has('deviceId')) {
      filters.deviceId = {
        filterType: 'text',
        type: 'equals',
        filter: searchParams.get('deviceId'),
      };
    }

    if (searchParams.has('deviceTypeId')) {
      filters['deviceType.deviceTypeId'] = {
        filterType: 'text',
        type: 'equals',
        filter: searchParams.get('deviceTypeId'),
      };
    }

    if (searchParams.has('deviceSubtypeId')) {
      filters['deviceSubtype.deviceTypeId'] = {
        filterType: 'text',
        type: 'equals',
        filter: searchParams.get('deviceSubtypeId'),
      };
    }

    if (searchParams.has('importId')) {
      filters.importId = {
        filterType: 'text',
        type: 'equals',
        filter: searchParams.get('importId'),
      };
    }

    return filters;
  });

  useEffect(() => {
    setSearchParams(
      (params) => {
        params.set('tab', tab);
        return params;
      },
      { replace: true }
    );
  }, [tab]);

  useEffect(() => {
    setSearchParams(
      (params) => {
        params.delete('organizationId');
        params.delete('departmentId');
        params.delete('deviceId');
        params.delete('deviceTypeId');
        params.delete('deviceSubtypeId');
        params.delete('importId');
        return params;
      },
      { replace: true }
    );
  }, []);

  return (
    <DashboardLayout
      title={tab === 'calendar' ? 'Sledovanie termínov' : undefined}
      height="screen"
      breadcrumbs={[{ title: 'Sledovanie termínov' }]}
      actions={<TabBox text="Zobraziť ako tabuľku" icon={<IconBorderAll />} onClick={() => setTab('table')} isActive />}
      groupPosition="left"
    >
      {tab === 'table' && (
        <RevisionPlanTable
          initialFilters={initialFilters}
          afterTitle={
            <TabBox text="Zobraziť ako kalendár" icon={<IconCalendar />} onClick={() => setTab('calendar')} isActive />
          }
        />
      )}
      {tab === 'calendar' && <RevisionPlanCalendar />}
    </DashboardLayout>
  );
}
