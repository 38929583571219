import { ActionIcon, Box, Progress, Stack, TextInput } from '@mantine/core';
import FileTypeIcon from 'components/files/upload/FileTypeIcon';
import { IconTrashX, IconX } from '@tabler/icons-react';
import P2Regular from 'components/typography/P2Regular';

/**
 * Indicator for a file upload.
 */
export default function UploadProgressIndicator({
  fileName,
  fileType,
  progress,
  finalized,
  onDelete,
  onFileNameChange,
}: {
  fileName: string;
  fileType: string;
  progress: number;
  finalized: boolean;
  onDelete: () => void;
  onFileNameChange: (fileName: string) => void;
}) {
  return (
    <Stack spacing={0} className="overflow-hidden" py={4} pr={16}>
      <div className="grid grid-cols-[auto_1fr_auto] items-center justify-start gap-2">
        <Box w={24} h={24}>
          <FileTypeIcon w={24} h={24} type={fileType} />
        </Box>
        {finalized ? (
          <TextInput size="sm" value={fileName} onChange={(e) => onFileNameChange(e.currentTarget.value)} />
        ) : (
          <P2Regular>{fileName}</P2Regular>
        )}
        <div className="cursor-pointer" onClick={onDelete}>
          <ActionIcon size="sm" variant="danger-secondary" p={6}>
            {finalized ? <IconTrashX /> : <IconX />}
          </ActionIcon>
        </div>
      </div>
      {!finalized && <Progress mt={-4} ml={32} h={4} value={progress * 100} />}
    </Stack>
  );
}
