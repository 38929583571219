import { SweetPath } from 'sweet-path';

// Private module
export const TEST_PAGE_PATH = new SweetPath('/private/test');

// Profile module
export const WELCOME_PAGE_PATH = new SweetPath('/welcome');
export const LOGIN_PAGE_PATH = new SweetPath('/login');
export const RESET_PASSWORD_PAGE_PATH = new SweetPath('/reset-password');
export const SET_NEW_PASSWORD_PAGE_PATH = new SweetPath('/set-new-password');
export const EXPIRED_RESET_LINK_PAGE_PATH = new SweetPath('/expired-link');
export const MY_PROFILE_PAGE_PATH = new SweetPath('/profile');
export const MY_CERTIFICATES_PAGE_PATH = new SweetPath('/my-certificates');
export const MY_COMPANY_SETTINGS_PAGE_PATH = new SweetPath('/my-company-settings');
export const NOTIFICATION_SETTINGS_PAGE_PATH = new SweetPath('/notifications-settings/:userId');
export const LOGOUT_PAGE_PATH = new SweetPath('/logout');
export const SETTINGS_PAGE_PATH = new SweetPath('/settings');
export const DEVICE_TYPES_PAGE_PATH = new SweetPath('/device-types');
export const DEVICE_SUBTYPES_PAGE_PATH = new SweetPath('/device-types/:deviceTypeId/subtypes');
export const ADD_DEVICE_SUBTYPE_PAGE_PATH = new SweetPath('/device-types/:deviceTypeId/subtypes/add');
export const EDIT_DEVICE_SUBTYPE_PAGE_PATH = new SweetPath<'deviceTypeId' | 'deviceSubtypeId'>(
  '/device-types/:deviceTypeId/subtypes/edit/:deviceSubtypeId'
);
export const REVISION_TEMPLATES_PAGE_PATH = new SweetPath('/revision-templates');
export const ADD_REVISION_TEMPLATE_PAGE_PATH = new SweetPath('/revision-templates/add');
export const EDIT_REVISION_TEMPLATE_PAGE_PATH = new SweetPath<'revisionTemplateId'>(
  '/revision-templates/edit/:revisionTemplateId'
);
export const COPY_REVISION_TEMPLATE_PAGE_PATH = new SweetPath<'revisionTemplateId'>(
  '/revision-templates/copy/:revisionTemplateId'
);

// Admin module
export const USERS_PAGE_PATH = new SweetPath('/users');
export const ADD_USER_PAGE_PATH = new SweetPath('/users/add');
export const EDIT_USER_PAGE_PATH = new SweetPath<'userId'>('/users/edit/:userId');
export const ORGANIZATIONS_PAGE_PATH = new SweetPath('/organizations');
export const ADD_ORGANIZATION_PAGE_PATH = new SweetPath('/organizations/add');
export const EDIT_ORGANIZATION_PAGE_PATH = new SweetPath<'organizationId'>('/organizations/edit/:organizationId');
export const DEPARTMENTS_PAGE_PATH = new SweetPath('/departments');
export const ADD_DEPARTMENT_PAGE_PATH = new SweetPath('/departments/add');
export const EDIT_DEPARTMENT_PAGE_PATH = new SweetPath<'departmentId'>('/departments/edit/:departmentId');
export const DASHBOARD_PAGE_PATH = new SweetPath('/');
export const EXPORT_PAGE_PATH = new SweetPath('/export');

// Pre-defined data module
export const USERS_PREDEFINED_DATA_PAGE_PATH = new SweetPath('/predefined-data/user');
export const ADD_USERS_PREDEFINED_DATA_PAGE_PATH = new SweetPath('/predefined-data/user/add');
export const EDIT_USERS_PREDEFINED_DATA_PAGE_PATH = new SweetPath<'predefinedDataId'>(
  '/predefined-data/user/edit/:predefinedDataId'
);
export const GLOBAL_PREDEFINED_DATA_PAGE_PATH = new SweetPath('/predefined-data/global');
export const ADD_GLOBAL_PREDEFINED_DATA_PAGE_PATH = new SweetPath('/predefined-data/global/add');
export const EDIT_GLOBAL_PREDEFINED_DATA_PAGE_PATH = new SweetPath<'predefinedDataId'>(
  '/predefined-data/global/edit/:predefinedDataId'
);
export const MEASURING_DEVICES_PAGE_PATH = new SweetPath('/measuring-devices');
export const ADD_MEASURING_DEVICE_PAGE_PATH = new SweetPath('/measuring-devices/add');
export const EDIT_MEASURING_DEVICE_PAGE_PATH = new SweetPath<'measuringDeviceId'>(
  '/measuring-devices/edit/:measuringDeviceId'
);

// Revisions module
export const REVISIONS_PAGE_PATH = new SweetPath('/revisions');
export const DEVICES_PAGE_PATH = new SweetPath('/devices');
export const FAULTS_PAGE_PATH = new SweetPath('/faults');
export const ADD_DEVICE_PAGE_PATH = new SweetPath('/devices/add');
export const EDIT_DEVICE_PAGE_PATH = new SweetPath<'deviceId'>('/devices/edit/:deviceId');
export const DEVICE_FAULTS_PAGE_PATH = new SweetPath('/devices/device/:deviceId/faults');
export const REVISION_PAGE_PATH = new SweetPath<'revisionId'>('/revisions/revision/:revisionId');
export const ADD_REVISION_PAGE_PATH = new SweetPath('/revisions/add');
export const COPY_REVISION_PAGE_PATH = new SweetPath<'revisionId'>('/revisions/copy/:revisionId');
export const EDIT_REVISION_PAGE_PATH = new SweetPath<'revisionId'>('/revisions/edit/:revisionId');
export const FILL_OUT_REVISION_PAGE_PATH = new SweetPath<'revisionId'>('/revisions/fill-out/:revisionId');
export const SELECT_TEMPLATE_AND_VISUAL_PAGE_PATH = new SweetPath<'revisionId'>(
  '/revisions/select-template/:revisionId'
);
export const DEVICE_REVISION_PLANS_PAGE_PATH = new SweetPath('/revision-plans');
export const TEMPLATE_EDITOR_PAGE_PATH = new SweetPath<'revisionTemplateId'>(
  '/revision-templates/template-editor/:revisionTemplateId'
);
