import { useMemo } from 'react';
import { SystemExportRow } from 'components/tables/data/system-export/types';
import DateDisplay from 'components/DateDisplay';

/**
 * Column for the timestamp.
 */
export default function CreatedAtColumn({ data: { createdAt } }: { data: SystemExportRow }) {
  const date = useMemo(() => new Date(createdAt), [createdAt]);
  return <DateDisplay withTime date={date} />;
}
