import { Box, Button, Group, Modal, Stack } from '@mantine/core';
import { IFileState } from 'components/files/upload/types';
import UploadFiles from 'components/files/upload/UploadFiles';
import { noop } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';

/**
 * The parameters of the add device file modal.
 */
export interface AddDeviceFileModalParams {
  onAdd?: (files: IFileState[]) => void;
  title?: string;
  opened: boolean;
  onClose: () => void;
}

/**
 * Modal for adding a file.
 *
 * @see {@link https://www.figma.com/file/M2RU8Nr32l3lDgCCM3PjVL/FM-Point?type=design&node-id=256-11634 Figma Design}
 */
export default function AddDeviceFileModal({
  onAdd = noop,
  title = 'Pridať súbory',
  opened,
  onClose,
}: AddDeviceFileModalParams) {
  const [files = [], setFiles] = useState<IFileState[] | undefined>([]);

  const loading = useMemo(() => files.some(({ finalized }) => !finalized), [files]);
  const disabled = useMemo(() => files.length === 0, [files]);

  const submit = useCallback(() => {
    onAdd(files);
    onClose();
  }, [files, onAdd, onClose]);

  useEffect(() => {
    if (!opened) {
      setFiles([]);
    }
  }, [opened]);

  return (
    <Modal opened={opened} onClose={onClose} title={title} size="auto">
      <Stack spacing={24}>
        <Box pt={12}>
          <UploadFiles onChange={setFiles} listMaxHeight="calc(100vh - 400px)" />
        </Box>
        <Group position="right">
          <Button variant="subtle-gray" onClick={onClose}>
            Zrušiť
          </Button>
          <Button
            variant="primary"
            type="submit"
            onClick={loading ? noop : submit}
            loading={loading}
            disabled={disabled}
          >
            Pridať súbory
          </Button>
        </Group>
      </Stack>
    </Modal>
  );
}
