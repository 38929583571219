import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { SystemExportCreatePayload } from 'api/actions/system-export-create/system-export-create-payload';
import { SystemExportCreatePathParameters } from 'api/actions/system-export-create/system-export-create-path-parameters';
import { SystemExportCreateResponse } from 'api/actions/system-export-create/system-export-create-response';

type SystemExportCreateQueryParameters = Record<string, any>;

/**
 * The path of the SystemExportCreate action.
 */
export const systemExportCreateActionPath = new SweetPath<keyof SystemExportCreatePathParameters>(
  '/api/system/export/create',
  { path: '{param}' }
);

/**
 * Creates a contextualized SystemExportCreate actions.
 */
export default function createSystemExportCreateAction(axios: AxiosInstance) {
  return function systemExportCreateAction({
    headers = {},
    query = {},
    payload,
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    query?: SystemExportCreateQueryParameters;
    payload: SystemExportCreatePayload;
  }) {
    const path = systemExportCreateActionPath.original;

    return axios.post<SystemExportCreateResponse, SystemExportCreateResponse, SystemExportCreatePayload>(path, payload);
  };
}
