import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom';

import {
  // Private module
  TEST_PAGE_PATH,

  // Profile module
  WELCOME_PAGE_PATH,
  LOGIN_PAGE_PATH,
  LOGOUT_PAGE_PATH,
  RESET_PASSWORD_PAGE_PATH,
  SET_NEW_PASSWORD_PAGE_PATH,
  MY_PROFILE_PAGE_PATH,
  MY_CERTIFICATES_PAGE_PATH,
  MY_COMPANY_SETTINGS_PAGE_PATH,
  EXPIRED_RESET_LINK_PAGE_PATH,
  NOTIFICATION_SETTINGS_PAGE_PATH,
  SETTINGS_PAGE_PATH,
  DEVICE_TYPES_PAGE_PATH,
  DEVICE_SUBTYPES_PAGE_PATH,
  ADD_DEVICE_SUBTYPE_PAGE_PATH,
  EDIT_DEVICE_SUBTYPE_PAGE_PATH,

  // Admin module
  USERS_PAGE_PATH,
  ADD_USER_PAGE_PATH,
  ORGANIZATIONS_PAGE_PATH,
  ADD_ORGANIZATION_PAGE_PATH,
  EDIT_ORGANIZATION_PAGE_PATH,
  ADD_DEPARTMENT_PAGE_PATH,
  EDIT_DEPARTMENT_PAGE_PATH,
  EDIT_USER_PAGE_PATH,
  DEPARTMENTS_PAGE_PATH,
  DASHBOARD_PAGE_PATH,
  EXPORT_PAGE_PATH,

  // Pre-defined data module
  USERS_PREDEFINED_DATA_PAGE_PATH,
  GLOBAL_PREDEFINED_DATA_PAGE_PATH,
  ADD_USERS_PREDEFINED_DATA_PAGE_PATH,
  ADD_GLOBAL_PREDEFINED_DATA_PAGE_PATH,
  EDIT_USERS_PREDEFINED_DATA_PAGE_PATH,
  EDIT_GLOBAL_PREDEFINED_DATA_PAGE_PATH,

  // Measuring devices module
  MEASURING_DEVICES_PAGE_PATH,
  ADD_MEASURING_DEVICE_PAGE_PATH,
  EDIT_MEASURING_DEVICE_PAGE_PATH,

  // Devices module
  DEVICES_PAGE_PATH,
  FAULTS_PAGE_PATH,
  ADD_DEVICE_PAGE_PATH,
  EDIT_DEVICE_PAGE_PATH,
  DEVICE_FAULTS_PAGE_PATH,

  // Revisions module
  REVISIONS_PAGE_PATH,
  REVISION_PAGE_PATH,
  ADD_REVISION_PAGE_PATH,
  COPY_REVISION_PAGE_PATH,
  FILL_OUT_REVISION_PAGE_PATH,
  EDIT_REVISION_PAGE_PATH,
  SELECT_TEMPLATE_AND_VISUAL_PAGE_PATH,
  DEVICE_REVISION_PLANS_PAGE_PATH,
  REVISION_TEMPLATES_PAGE_PATH,
  EDIT_REVISION_TEMPLATE_PAGE_PATH,
  COPY_REVISION_TEMPLATE_PAGE_PATH,
  ADD_REVISION_TEMPLATE_PAGE_PATH,
  TEMPLATE_EDITOR_PAGE_PATH,
} from 'routes/paths';

// Private module
import TestPage from 'pages/private-module/test/TestPage';

// Profile module
import WelcomePage from 'pages/profile-module/welcome/WelcomePage';

import LoginPage from 'pages/profile-module/session/LoginPage';
import LogoutPage from 'pages/profile-module/session/LogoutPage';

import ResetPasswordPage from 'pages/profile-module/password/ResetPasswordPage';
import SetNewPasswordPage from 'pages/profile-module/password/SetNewPasswordPage';
import ExpiredResetLinkPage from 'pages/profile-module/password/ExpiredResetLinkPage';

import MyProfilePage from 'pages/profile-module/personal/MyProfilePage';
import MyCertificatesPage from 'pages/profile-module/personal/MyCertificatesPage';
import MyCompanySettingsPage from 'pages/profile-module/personal/MyCompanySettingsPage';
import NotificationSettingsPage from 'pages/profile-module/personal/NotificationSettingsPage';

import SettingsPage from 'pages/profile-module/settings/SettingsPage';

// Admin module
import DashboardPage from 'pages/admin-module/dashboard/DashboardPage';

import UsersPage from 'pages/admin-module/user/UsersPage';
import AddUserPage from 'pages/admin-module/user/AddUserPage';
import EditUserPage from 'pages/admin-module/user/EditUserPage';

import OrganizationsPage from 'pages/admin-module/organization/OrganizationsPage';
import AddOrganizationPage from 'pages/admin-module/organization/AddOrganizationPage';
import EditOrganizationPage from 'pages/admin-module/organization/EditOrganizationPage';

import DepartmentsPage from 'pages/admin-module/department/DepartmentsPage';
import AddDepartmentPage from 'pages/admin-module/department/AddDepartmentPage';
import EditDepartmentPage from 'pages/admin-module/department/EditDepartmentPage';

// Pre-defined data module
import UsersPredefinedDataPage from 'pages/predefined-data-module/users/UsersPredefinedDataPage';
import AddUsersPredefinedDataPage from 'pages/predefined-data-module/users/AddUsersPredefinedDataPage';
import EditUsersPredefinedDataPage from 'pages/predefined-data-module/users/EditUsersPredefinedDataPage';

import GlobalPredefinedDataPage from 'pages/predefined-data-module/global/GlobalPredefinedDataPage';
import AddGlobalPredefinedDataPage from 'pages/predefined-data-module/global/AddGlobalPredefinedDataPage';
import EditGlobalPredefinedDataPage from 'pages/predefined-data-module/global/EditGlobalPredefinedDataPage';

import AddMeasuringDevicePage from 'pages/predefined-data-module/measuring-device/AddMeasuringDevicePage';
import EditMeasuringDevicePage from 'pages/predefined-data-module/measuring-device/EditMeasuringDevicePage';
import MeasuringDevicesPage from 'pages/predefined-data-module/measuring-device/MeasuringDevicesPage';

// Devices module
import DevicesPage from 'pages/devices-module/device/DevicesPage';
import AddDevicePage from 'pages/devices-module/device/AddDevicePage';
import EditDevicePage from 'pages/devices-module/device/EditDevicePage';
import FaultsPage from 'pages/devices-module/faults/FaultsPage';

import DeviceFaultsPage from 'pages/devices-module/device/DeviceFaultsPage';

import DeviceTypesPage from 'pages/devices-module/device-types/DeviceTypesPage';
import AddDeviceSubtypePage from 'pages/devices-module/device-types/device-subtypes/AddDeviceSubtypePage';
import EditDeviceSubtypePage from 'pages/devices-module/device-types/device-subtypes/EditDeviceSubtypePage';
import DeviceSubtypesPage from 'pages/devices-module/device-types/device-subtypes/DeviceSubtypesPage';

// Revisions module
import RevisionsPage from 'pages/revisions-module/revisions/RevisionsPage';
import RevisionPage from 'pages/revisions-module/revisions/RevisionPage';
import AddRevisionPage from 'pages/revisions-module/add-revision/AddRevisionPage';
import CopyRevisionPage from 'pages/revisions-module/copy-revision/CopyRevisionPage';
import EditRevisionPage from 'pages/revisions-module/edit-revision/EditRevisionPage';
import FillOutRevisionPage from 'pages/revisions-module/fill-out-revision/FillOutRevisionPage';
import SelectTemplateAndVisualPage from 'pages/revisions-module/select-template-and-visual/SelectTemplateAndVisualPage';
import RevisionPlansPage from 'pages/revisions-module/revision-plans/RevisionPlansPage';
import TemplateEditorPage from 'pages/revisions-module/template-editor/TemplateEditorPage';

// Route decorators
import RequireAuth from 'routes/decorators/RequireAuth';
import RequirePermission from 'routes/decorators/RequirePermission';

// Feature toggles
import {
  FEATURE_TOGGLE_BROWSE_REVISION_TEMPLATES,
  FEATURE_TOGGLE_CREATE_DEVICE_SUBTYPES,
  FEATURE_TOGGLE_EXPORT_PAGE,
  FEATURE_TOGGLE_REVISION_TEMPLATE_EDITOR,
  FEATURE_TOGGLE_TEST_PAGE,
} from 'env';

import RevisionTemplatesPage from 'pages/revisions-module/revision-template/RevisionTemplatesPage';
import EditRevisionTemplatePage from 'pages/revisions-module/revision-template/EditRevisionTemplatePage';
import AddRevisionTemplatePage from 'pages/revisions-module/revision-template/AddRevisionTemplatePage';
import CopyRevisionTemplatePage from 'pages/revisions-module/revision-template/CopyRevisionTemplatePage';
import SystemExportPage from 'pages/admin-module/system-export/SystemExportPage';

/**
 * All routes in the app must be registered here.
 */
export const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      {/* Test */}
      {FEATURE_TOGGLE_TEST_PAGE && <Route path={TEST_PAGE_PATH.original} element={<TestPage />} />}

      {/* Auth */}
      <Route path={LOGIN_PAGE_PATH.original} element={<LoginPage />} />
      <Route path={RESET_PASSWORD_PAGE_PATH.original} element={<ResetPasswordPage />} />
      <Route path={SET_NEW_PASSWORD_PAGE_PATH.original} element={<SetNewPasswordPage />} />
      <Route path={EXPIRED_RESET_LINK_PAGE_PATH.original} element={<ExpiredResetLinkPage />} />

      <Route element={<RequireAuth redirect={LOGIN_PAGE_PATH.original} />}>
        {/* Dashboard */}
        <Route path={DASHBOARD_PAGE_PATH.original} element={<DashboardPage />} />

        {/* Profile */}
        <Route path={WELCOME_PAGE_PATH.original} element={<WelcomePage />} />
        <Route path={MY_PROFILE_PAGE_PATH.original} element={<MyProfilePage />} />
        <Route path={SETTINGS_PAGE_PATH.original} element={<SettingsPage />} />
        <Route path={MY_CERTIFICATES_PAGE_PATH.original} element={<MyCertificatesPage />} />
        <Route path={MY_COMPANY_SETTINGS_PAGE_PATH.original} element={<MyCompanySettingsPage />} />
        <Route path={NOTIFICATION_SETTINGS_PAGE_PATH.original} element={<NotificationSettingsPage />} />
        <Route path={LOGOUT_PAGE_PATH.original} element={<LogoutPage />} />

        {/* Export */}
        {FEATURE_TOGGLE_EXPORT_PAGE && <Route path={EXPORT_PAGE_PATH.original} element={<SystemExportPage />} />}

        {/* Device subtypes */}
        {FEATURE_TOGGLE_CREATE_DEVICE_SUBTYPES && (
          <Route
            element={<RequirePermission permission="define-device-subtypes" redirect={DASHBOARD_PAGE_PATH.original} />}
          >
            <Route path={DEVICE_TYPES_PAGE_PATH.original} element={<DeviceTypesPage />} />
            <Route path={ADD_DEVICE_SUBTYPE_PAGE_PATH.original} element={<AddDeviceSubtypePage />} />
            <Route path={EDIT_DEVICE_SUBTYPE_PAGE_PATH.original} element={<EditDeviceSubtypePage />} />
            <Route path={DEVICE_SUBTYPES_PAGE_PATH.original} element={<DeviceSubtypesPage />} />
          </Route>
        )}

        {/* Revision templates */}
        {FEATURE_TOGGLE_BROWSE_REVISION_TEMPLATES && (
          <>
            <Route path={REVISION_TEMPLATES_PAGE_PATH.original} element={<RevisionTemplatesPage />} />
            <Route path={EDIT_REVISION_TEMPLATE_PAGE_PATH.original} element={<EditRevisionTemplatePage />} />
            {FEATURE_TOGGLE_REVISION_TEMPLATE_EDITOR && (
              <>
                <Route path={ADD_REVISION_TEMPLATE_PAGE_PATH.original} element={<AddRevisionTemplatePage />} />
                <Route path={COPY_REVISION_TEMPLATE_PAGE_PATH.original} element={<CopyRevisionTemplatePage />} />
                <Route path={TEMPLATE_EDITOR_PAGE_PATH.original} element={<TemplateEditorPage />} />
              </>
            )}
          </>
        )}

        {/* Users */}
        <Route element={<RequirePermission permission="manage-users" redirect={DASHBOARD_PAGE_PATH.original} />}>
          <Route path={USERS_PAGE_PATH.original} element={<UsersPage />} />
          <Route path={ADD_USER_PAGE_PATH.original} element={<AddUserPage />} />
          <Route path={EDIT_USER_PAGE_PATH.original} element={<EditUserPage />} />
        </Route>

        {/* Organizations */}
        <Route
          element={<RequirePermission permission="manage-organizations" redirect={DASHBOARD_PAGE_PATH.original} />}
        >
          <Route path={ORGANIZATIONS_PAGE_PATH.original} element={<OrganizationsPage />} />
          <Route path={ADD_ORGANIZATION_PAGE_PATH.original} element={<AddOrganizationPage />} />
          <Route path={EDIT_ORGANIZATION_PAGE_PATH.original} element={<EditOrganizationPage />} />
        </Route>

        {/* Departments */}
        <Route element={<RequirePermission permission="manage-departments" redirect={DASHBOARD_PAGE_PATH.original} />}>
          <Route path={DEPARTMENTS_PAGE_PATH.original} element={<DepartmentsPage />} />
          <Route path={ADD_DEPARTMENT_PAGE_PATH.original} element={<AddDepartmentPage />} />
          <Route path={EDIT_DEPARTMENT_PAGE_PATH.original} element={<EditDepartmentPage />} />
        </Route>

        {/* User pre-defined data */}
        <Route path={USERS_PREDEFINED_DATA_PAGE_PATH.original} element={<UsersPredefinedDataPage />} />
        <Route path={ADD_USERS_PREDEFINED_DATA_PAGE_PATH.original} element={<AddUsersPredefinedDataPage />} />
        <Route path={EDIT_USERS_PREDEFINED_DATA_PAGE_PATH.original} element={<EditUsersPredefinedDataPage />} />

        {/* Global pre-defined data */}
        <Route
          element={
            <RequirePermission permission="manage-global-predefined-data" redirect={DASHBOARD_PAGE_PATH.original} />
          }
        >
          <Route path={GLOBAL_PREDEFINED_DATA_PAGE_PATH.original} element={<GlobalPredefinedDataPage />} />
          <Route path={ADD_GLOBAL_PREDEFINED_DATA_PAGE_PATH.original} element={<AddGlobalPredefinedDataPage />} />
          <Route path={EDIT_GLOBAL_PREDEFINED_DATA_PAGE_PATH.original} element={<EditGlobalPredefinedDataPage />} />
        </Route>

        {/* Measuring devices */}
        <Route path={MEASURING_DEVICES_PAGE_PATH.original} element={<MeasuringDevicesPage />} />
        <Route path={ADD_MEASURING_DEVICE_PAGE_PATH.original} element={<AddMeasuringDevicePage />} />
        <Route path={EDIT_MEASURING_DEVICE_PAGE_PATH.original} element={<EditMeasuringDevicePage />} />

        {/* Devices */}
        <Route element={<RequirePermission permission="browse-devices" redirect={DASHBOARD_PAGE_PATH.original} />}>
          <Route path={DEVICES_PAGE_PATH.original} element={<DevicesPage />} />
          <Route path={FAULTS_PAGE_PATH.original} element={<FaultsPage />} />
          <Route element={<RequirePermission permission="manage-devices" redirect={DASHBOARD_PAGE_PATH.original} />}>
            <Route path={ADD_DEVICE_PAGE_PATH.original} element={<AddDevicePage />} />
          </Route>
          <Route path={EDIT_DEVICE_PAGE_PATH.original} element={<EditDevicePage />} />
          <Route path={DEVICE_FAULTS_PAGE_PATH.original} element={<DeviceFaultsPage />} />
          <Route path={DEVICE_REVISION_PLANS_PAGE_PATH.original} element={<RevisionPlansPage />} />
        </Route>

        {/* Revisions */}
        <Route element={<RequirePermission permission="*-revisions" redirect={DASHBOARD_PAGE_PATH.original} />}>
          <Route path={REVISIONS_PAGE_PATH.original} element={<RevisionsPage />} />
          <Route path={REVISION_PAGE_PATH.original} element={<RevisionPage />} />
        </Route>
        <Route element={<RequirePermission permission="assign-revisions" redirect={DASHBOARD_PAGE_PATH.original} />}>
          <Route path={ADD_REVISION_PAGE_PATH.original} element={<AddRevisionPage />} />
          <Route path={COPY_REVISION_PAGE_PATH.original} element={<CopyRevisionPage />} />
          <Route path={EDIT_REVISION_PAGE_PATH.original} element={<EditRevisionPage />} />
        </Route>
        <Route element={<RequirePermission permission="fill-out-revisions" redirect={DASHBOARD_PAGE_PATH.original} />}>
          <Route path={SELECT_TEMPLATE_AND_VISUAL_PAGE_PATH.original} element={<SelectTemplateAndVisualPage />} />
          <Route path={FILL_OUT_REVISION_PAGE_PATH.original} element={<FillOutRevisionPage />} />
        </Route>
      </Route>
    </>
  )
);
