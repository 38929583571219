import { IFileError } from 'components/files/upload/types';

import { FILE_UPLOAD_MAX_SIZE } from 'env';
import formatFileSize from 'utils/format-file-size';

/**
 * Returns an error message text.
 */
export default function getErrorMessage(error: IFileError) {
  switch (error.reason) {
    case 'accept': {
      return (
        <span>
          Súbor {error.fileName} <b>nie je podporovaný</b>.
        </span>
      );
    }

    case 'size': {
      const maxSize = formatFileSize(FILE_UPLOAD_MAX_SIZE, { maxDecimals: 0 });

      return (
        <span>
          Súbor {error.fileName} <b>prekročil limit {maxSize}</b>.
        </span>
      );
    }

    case 'maxFiles':
      return <span>Súbor "{error.fileName}" nebol nahraný, pretože bol dosiahnutý maximálny počet súborov.</span>;

    default:
      return <span>Neznáma chyba.</span>;
  }
}
