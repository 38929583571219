import UserTable from 'components/tables/data/user/UserTable';
import DashboardLayout from 'layouts/dashboard/DashboardLayout';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

/**
 * Page used to display a list of users.
 *
 * - {@link https://www.figma.com/file/M2RU8Nr32l3lDgCCM3PjVL/FM-Point?node-id=233%3A8532 Figma Design}
 * - {@link https://www.notion.so/Administrative-List-Users-5bf19a6ae3124e39a2e827dc477efa3a?pvs=4 Notion Page}
 */
export default function UsersPage() {
  const [searchParams, setSearchParams] = useSearchParams();

  const [initialFilters] = useState(() => {
    const filters: Record<string, any> = {};

    if (searchParams.has('organizationId')) {
      filters['organization.organizationId'] = {
        filterType: 'text',
        type: 'equals',
        filter: searchParams.get('organizationId'),
      };
    }

    return filters;
  });

  useEffect(() => {
    setSearchParams((params) => {
      params.delete('organizationId');
      return params;
    });
  }, []);

  return (
    <DashboardLayout breadcrumbs={[{ title: 'Používatelia' }]} height="screen">
      <UserTable initialFilters={initialFilters} />
    </DashboardLayout>
  );
}
