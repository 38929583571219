import FaultTable from 'components/tables/data/fault/FaultTable';
import DashboardLayout from 'layouts/dashboard/DashboardLayout';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

/**
 * Displays a list of all faults for all devices.
 */
export default function FaultsPage() {
  const [searchParams, setSearchParams] = useSearchParams();

  const [initialFilters] = useState(() => {
    const filters: Record<string, any> = {};

    if (searchParams.has('isFixedText')) {
      filters.isFixedText = {
        filterType: 'text',
        type: 'equals',
        filter: searchParams.get('isFixedText'),
      };
    }

    if (searchParams.has('organizationId')) {
      filters['organization.organizationId'] = {
        filterType: 'text',
        type: 'equals',
        filter: searchParams.get('organizationId'),
      };
    }

    if (searchParams.has('departmentId')) {
      filters['department.departmentId'] = {
        filterType: 'text',
        type: 'equals',
        filter: searchParams.get('departmentId'),
      };
    }

    return filters;
  });

  useEffect(() => {
    setSearchParams(
      (params) => {
        params.delete('isFixedText');
        params.delete('organizationId');
        params.delete('departmentId');
        return params;
      },
      { replace: true }
    );
  }, []);

  return (
    <DashboardLayout height="screen" breadcrumbs={[{ title: 'Závady zariadení' }]}>
      <FaultTable initialFilters={initialFilters} />
    </DashboardLayout>
  );
}
