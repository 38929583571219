import DataTable from 'components/tables/DataTable';
import { Fragment } from 'react';
import { hiddenSearchableColumn as hiddenSearchable } from 'components/tables/utils/hidden-searchable-column';
import { ColDef } from 'ag-grid-community';
import OrganizationSelectFilter from 'components/tables/filters/OrganizationSelectFilter';
import CreatedAtColumn from 'components/tables/data/system-export/columns/CreatedAtColumn';
import OrganizationNameColumn from 'components/tables/data/system-export/columns/OrganizationNameColumn';
import FileSizeColumn from 'components/tables/data/system-export/columns/FileSizeColumn';
import FileNameColumn from 'components/tables/data/system-export/columns/FileNameColumn';
import AuthorNameColumn from 'components/tables/data/system-export/columns/AuthorNameColumn';
import ActionsColumn from 'components/tables/data/system-export/columns/ActionsColumn';
import { IconDatabaseExport } from '@tabler/icons-react';
import SystemExportModal from 'components/tables/data/system-export/SystemExportModal';
import { useCounter, useDisclosure } from '@mantine/hooks';
import { useApi } from 'api/api-context';

/**
 * The columns of the table.
 */
const columns: ColDef[] = [
  // ANCHOR Visible Columns
  {
    field: 'organization.organizationName',
    headerName: 'Organizácia',
    minWidth: 200,
    sortable: true,
    resizable: true,
    filter: true,
    floatingFilter: true,
    floatingFilterComponent: OrganizationSelectFilter,
    unSortIcon: true,
    wrapText: true,
    cellRenderer: OrganizationNameColumn,
  },
  {
    field: 'createdAt',
    headerName: 'Čas exportu',
    sortable: true,
    resizable: true,
    unSortIcon: true,
    minWidth: 175,
    cellRenderer: CreatedAtColumn,
  },
  {
    field: 'author.fullName',
    headerName: 'Autor',
    sortable: true,
    resizable: true,
    unSortIcon: true,
    minWidth: 200,
    cellRenderer: AuthorNameColumn,
  },
  {
    field: 'fileName',
    headerName: 'Súbor',
    sortable: true,
    resizable: true,
    unSortIcon: true,
    minWidth: 300,
    cellRenderer: FileNameColumn,
  },
  {
    field: 'fileSize',
    headerName: 'Veľkosť súboru',
    sortable: true,
    resizable: true,
    unSortIcon: true,
    minWidth: 100,
    cellRenderer: FileSizeColumn,
  },

  // ANCHOR Hidden Columns
  { field: 'organization.organizationId', headerName: 'ID organizácie', ...hiddenSearchable },

  // ANCHOR Actions
  {
    field: '_actions',
    headerName: '',
    pinned: 'right',
    width: 194,
    minWidth: 194,
    maxWidth: 194,
    lockPosition: 'right',
    cellRenderer: ActionsColumn,
  },
];

/**
 * Table which displays organizations.
 */
export default function SystemExportTable() {
  const { getAction } = useApi();
  const [modalOpened, { open: openModal, close: closeModal }] = useDisclosure(false);
  const [refreshToken, { increment: incRefreshToken }] = useCounter(0);

  return (
    <Fragment>
      <DataTable
        tableId="system-exports"
        title="Systémový export"
        columns={columns}
        action={getAction('SystemExportList')}
        hideToggleDiscarded
        addButtonText="Nový export"
        addButtonIcon={<IconDatabaseExport />}
        onAddButtonClick={openModal}
        dataRefreshToken={refreshToken}
      />
      <SystemExportModal opened={modalOpened} onClose={closeModal} onExport={incRefreshToken} />
    </Fragment>
  );
}
