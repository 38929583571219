import { ActionIcon, Group, Tooltip } from '@mantine/core';
import RevisionSelect from 'components/selects/RevisionSelect';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { REVISION_PAGE_PATH } from 'routes/paths';
import { useFillOutRevisionDataProvider } from 'components/forms/revision/fill-out/data/FillOutRevisionDataProvider';
import { IconEye } from '@tabler/icons-react';

interface RevisionSelectWithPreviewProps {
  value: number | null;
  onChange: (value: number | null) => void;
}

/**
 * Component for selecting revision and previewing it.
 */
export default function RevisionSelectWithPreview({ value, onChange }: RevisionSelectWithPreviewProps) {
  const { revision } = useFillOutRevisionDataProvider();

  const previewPath = useMemo(() => REVISION_PAGE_PATH.insert({ revisionId: value }), [value]);

  return (
    <Group spacing={8} align="flex-end" className="grid grid-cols-[1fr_auto]">
      <RevisionSelect
        label="Vyberte revíznu správu z ktorej chcete kopírovať dáta"
        placeholder="Vyberte revíznu správu"
        templateSlug={revision.revisionTemplate?.slug}
        excludeRevisionId={revision.revisionId}
        value={String(value || '')}
        onChange={(value) => (value ? onChange(Number(value)) : onChange(null))}
        excludeRevisionsWithoutData
      />
      <Tooltip label="Detail revíznej správy" openDelay={300}>
        <ActionIcon size="md" variant="secondary" component={Link} to={previewPath} target="_blank" disabled={!value}>
          <IconEye />
        </ActionIcon>
      </Tooltip>
    </Group>
  );
}
