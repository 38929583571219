import { Group, Stack } from '@mantine/core';
import P2Medium from 'components/typography/P2Medium';
import P2Regular from 'components/typography/P2Regular';
import P4Regular from 'components/typography/P4Regular';
import { RevisionChange } from 'components/forms/revision/fill-out/types';

/**
 * Single change in a revision.
 */
export default function ChangeBox({ subTitle, before = '(nevyplnené)', after = '(nevyplnené)' }: RevisionChange) {
  return (
    <Stack pl={32} spacing={0}>
      {subTitle && (
        <P2Medium color="gray.9" mb={4}>
          {subTitle}
        </P2Medium>
      )}
      {!!before && (
        <Group spacing={24} noWrap align="start" className="grid grid-cols-[auto_1fr]">
          <P4Regular w={60} align="right" color="gray.6" mt={4}>
            {after ? 'Pôvodne:' : 'Zmazané:'}
          </P4Regular>
          <P2Regular color="gray.7" strikethrough>
            {before}
          </P2Regular>
        </Group>
      )}
      {!!after && (
        <Group spacing={24} noWrap align="start" className="grid grid-cols-[auto_1fr]">
          <P4Regular w={60} align="right" color="gray.6" mt={4}>
            {before ? 'Zmenené:' : 'Pridané:'}
          </P4Regular>
          <P2Medium color="red.9">{after}</P2Medium>
        </Group>
      )}
    </Stack>
  );
}
