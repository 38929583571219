import RevisionTable from 'components/tables/data/revision/RevisionTable';
import DashboardLayout from 'layouts/dashboard/DashboardLayout';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

/**
 * Page used to display revisions.
 *
 * - {@link https://www.figma.com/file/M2RU8Nr32l3lDgCCM3PjVL/FM-Point?node-id=233%3A10497 Figma Design}
 * - {@link https://www.notion.so/Revisions-List-Revisions-7f2582bc435a42f3b77b9c52debd5602?pvs=4 Notion Page}
 */
export default function RevisionsPage() {
  const [searchParams, setSearchParams] = useSearchParams();

  const [initialFilters] = useState(() => {
    const filters: Record<string, any> = {};

    if (searchParams.has('organizationId')) {
      filters['organization.organizationId'] = {
        filterType: 'text',
        type: 'equals',
        filter: searchParams.get('organizationId'),
      };
    }

    if (searchParams.has('departmentId')) {
      filters['department.departmentId'] = {
        filterType: 'text',
        type: 'equals',
        filter: searchParams.get('departmentId'),
      };
    }

    if (searchParams.has('deviceTypeId')) {
      filters['deviceType.deviceTypeId'] = {
        filterType: 'text',
        type: 'equals',
        filter: searchParams.get('deviceTypeId'),
      };
    }

    if (searchParams.has('deviceSubtypeId')) {
      filters['deviceSubtypes.deviceTypeId'] = {
        filterType: 'text',
        type: 'equals',
        filter: searchParams.get('deviceSubtypeId'),
      };
    }

    if (searchParams.has('deviceId')) {
      filters['_search.deviceId'] = {
        filterType: 'text',
        type: 'contains',
        filter: searchParams.get('deviceId'),
      };
    }

    if (searchParams.has('assignedBy')) {
      filters['assignedBy.organizationId'] = {
        filterType: 'text',
        type: 'equals',
        filter: searchParams.get('assignedBy'),
      };
    }

    if (searchParams.has('technician')) {
      filters['technician.userId'] = {
        filterType: 'text',
        type: 'equals',
        filter: searchParams.get('technician'),
      };
    }

    if (searchParams.has('deadlineStatus')) {
      filters['deadlineStatus'] = {
        filterType: 'text',
        type: 'equals',
        filter: searchParams.get('deadlineStatus'),
      };
    }

    if (searchParams.has('safetyPoint')) {
      filters['safetyPoint'] = {
        filterType: 'text',
        type: 'equals',
        filter: searchParams.get('safetyPoint'),
      };
    }

    if (searchParams.has('revisionStatusId')) {
      filters['revisionStatus.revisionStatusId'] = {
        filter: searchParams.get('revisionStatusId')!.split(',').map(Number).filter(Boolean),
      };
    }

    return filters;
  });

  useEffect(() => {
    setSearchParams(
      (params) => {
        params.delete('organizationId');
        params.delete('departmentId');
        params.delete('deviceTypeId');
        params.delete('deviceSubtypeId');
        params.delete('deviceId');
        params.delete('assignedBy');
        params.delete('technician');
        params.delete('deadlineStatus');
        params.delete('safetyPoint');
        params.delete('revisionStatusId');
        return params;
      },
      { replace: true }
    );
  }, []);

  return (
    <DashboardLayout height="screen" breadcrumbs={[{ title: 'Revízne správy' }]}>
      <RevisionTable initialFilters={initialFilters} />
    </DashboardLayout>
  );
}
