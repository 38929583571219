import { ScrollArea, Skeleton, Stack, SystemProp } from '@mantine/core';
import UploadProgressIndicator from 'components/files/upload/UploadProgressIndicator';
import { IFileState } from 'components/files/upload/types';
import { times } from 'lodash';
import { CSSProperties } from 'react';

/**
 * Displays a list of files being uploaded and files that have been uploaded.
 */
export default function UploadFilesList({
  files,
  onDelete,
  onFileNameChange,
  loading,
  placeholderCount,
  hideUploadedFiles = false,
  maxHeight = Infinity,
}: {
  files: IFileState[];
  onDelete: (uuid: string) => void;
  onFileNameChange: (uuid: string, fileName: string) => void;
  loading: boolean;
  placeholderCount: number;
  hideUploadedFiles?: boolean;
  maxHeight?: SystemProp<CSSProperties['maxHeight']>;
}) {
  if (loading) {
    return (
      <>
        {times(placeholderCount, (i) => (
          <Skeleton key={i} w="100%" h={24} radius={8} />
        ))}
      </>
    );
  }

  return (
    <ScrollArea.Autosize mah={maxHeight}>
      <Stack spacing={0}>
        {files
          .filter(({ progress }) => !hideUploadedFiles || progress < 1)
          .map(({ uuid, fileName, fileType, progress, finalized }: IFileState) => (
            <UploadProgressIndicator
              key={uuid}
              fileName={fileName}
              fileType={fileType}
              progress={progress}
              finalized={finalized}
              onDelete={() => onDelete(uuid)}
              onFileNameChange={(newFileName) => onFileNameChange(uuid, newFileName)}
            />
          ))}
      </Stack>
    </ScrollArea.Autosize>
  );
}
