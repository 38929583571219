import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { SystemExportDeletePathParameters } from 'api/actions/system-export-delete/system-export-delete-path-parameters';
import { SystemExportDeleteResponse } from 'api/actions/system-export-delete/system-export-delete-response';

type SystemExportDeleteQueryParameters = Record<string, any>;

/**
 * The path of the SystemExportDelete action.
 */
export const systemExportDeleteActionPath = new SweetPath<keyof SystemExportDeletePathParameters>(
  '/api/system/export/delete/{systemExportId}',
  { path: '{param}' }
);

/**
 * Creates a contextualized SystemExportDelete actions.
 */
export default function createSystemExportDeleteAction(axios: AxiosInstance) {
  return function systemExportDeleteAction({
    headers = {},
    parameters,
    query = {},
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    parameters: SystemExportDeletePathParameters;
    query?: SystemExportDeleteQueryParameters;
  }) {
    const path = systemExportDeleteActionPath.insert(parameters);

    return axios.post<SystemExportDeleteResponse, SystemExportDeleteResponse>(path, { params: query, headers });
  };
}
