import { ADD_DEPARTMENT_PAGE_PATH } from 'routes/paths';
import { ColDef } from 'ag-grid-community';
import { useApi } from 'api/api-context';
import DataTable, { IDataTablePublic } from 'components/tables/DataTable';
import { forwardRef, useCallback } from 'react';
import OrganizationSelectFilter from 'components/tables/filters/OrganizationSelectFilter';
import { DepartmentRow } from 'components/tables/data/department/types';
import DepartmentNameColumn from 'components/tables/data/department/columns/DepartmentNameColumn';
import OrganizationNameColumn from 'components/tables/data/department/columns/OrganizationNameColumn';
import AddressColumn from 'components/tables/data/department/columns/AddressColumn';
import ContactPersonColumn from 'components/tables/data/department/columns/ContactPersonColumn';
import ActionsColumn from 'components/tables/data/department/columns/ActionsColumn';

interface DepartmentTableProps {
  suppressSaveProfiles?: boolean;
  suppressProfileChangedNotification?: boolean;
  initialFilters?: Record<string, any>;
}

/**
 * The columns of the table.
 */
const columns: ColDef[] = [
  { field: 'departmentId', headerName: 'ID strediska', hide: true },
  {
    field: 'departmentName',
    headerName: 'Názov',
    minWidth: 200,
    sortable: true,
    resizable: true,
    unSortIcon: true,
    wrapText: true,
    cellRenderer: DepartmentNameColumn,
  },
  { field: 'departmentNumber', headerName: 'Číslo strediska', hide: true },
  {
    field: 'organization.organizationId',
    headerName: 'ID organizácie',
    hide: true,
    filter: true,
  },
  {
    field: 'organization.organizationName',
    headerName: 'Organizácia',
    minWidth: 200,
    filter: true,
    floatingFilter: true,
    floatingFilterComponent: OrganizationSelectFilter.configure({ permissionSlug: 'manage-departments' }),
    resizable: true,
    sortable: true,
    unSortIcon: true,
    wrapText: true,
    cellRenderer: OrganizationNameColumn,
  },
  { field: 'streetAddress', headerName: 'Ulica', hide: true },
  { field: 'city', headerName: 'Mesto', hide: true },
  { field: 'zip', headerName: 'PSČ', hide: true },
  { field: 'country', headerName: 'Krajina', hide: true },
  {
    field: 'address',
    headerName: 'Adresa',
    minWidth: 200,
    resizable: true,
    wrapText: true,
    cellRenderer: AddressColumn,
  },
  { field: 'contactPerson.userId', headerName: 'ID kontaktnej osoby', hide: true },
  { field: 'contactPerson.fullName', headerName: 'Meno kontaktnej osoby', hide: true },
  {
    field: 'contactPerson.firstName',
    headerName: '',
    filter: true,
    width: 0,
    minWidth: 0,
    maxWidth: 0,
    cellStyle: { opacity: 0 },
  },
  {
    field: 'contactPerson.lastName',
    headerName: '',
    filter: true,
    width: 0,
    minWidth: 0,
    maxWidth: 0,
    cellStyle: { opacity: 0 },
  },
  {
    field: 'contactPerson.email',
    headerName: '',
    filter: true,
    width: 0,
    minWidth: 0,
    maxWidth: 0,
    cellStyle: { opacity: 0 },
  },
  {
    field: 'contactPerson',
    headerName: 'Kontaktná osoba',
    minWidth: 200,
    resizable: true,
    wrapText: true,
    cellRenderer: ContactPersonColumn,
  },
  {
    valueGetter: ({ data: { status } }: { data: DepartmentRow }) => (status ? '1' : '0'),
    field: 'status',
    headerName: 'Aktívne',
    filter: true,
    minWidth: 0,
    maxWidth: 0,
    width: 0,
    cellStyle: { opacity: 0 },
  },
  {
    field: '_actions',
    headerName: '',
    pinned: 'right',
    width: 194,
    minWidth: 194,
    maxWidth: 194,
    cellRenderer: ActionsColumn,
  },
];

/**
 * Table which displays departments.
 */
const DepartmentTable = forwardRef<IDataTablePublic, DepartmentTableProps>(
  ({ initialFilters, suppressSaveProfiles, suppressProfileChangedNotification }, ref) => {
    const { getAction } = useApi();

    const action = useCallback(() => {
      const action = getAction('DepartmentList');
      return action({ query: { filters: { 'permissionSlug.eq': 'manage-departments' } } });
    }, [getAction]);

    return (
      <DataTable
        ref={ref}
        tableId="departments"
        title="Strediská"
        addButtonText="Pridať nové"
        toggleDiscardedLabel="Zobraziť vyradené"
        columns={columns}
        action={action}
        addButtonTarget={ADD_DEPARTMENT_PAGE_PATH.original}
        initialFilters={initialFilters}
        suppressSaveProfiles={suppressSaveProfiles}
        suppressProfileChangedNotification={suppressProfileChangedNotification}
        dataExport={{
          modalTitle: 'Exportovať strediská',
          fileName: 'strediska.xlsx',
          columnKeys: [
            'departmentId',
            'departmentName',
            'departmentNumber',
            'streetAddress',
            'city',
            'zip',
            'country',
            'status',
            'contactPerson.userId',
            'contactPerson.fullName',
            'organization.organizationId',
            'organization.organizationName',
          ],
        }}
      />
    );
  }
);

export default DepartmentTable;
