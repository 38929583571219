import { Center, Group, Stack, rem, useMantineTheme } from '@mantine/core';
import { IconAlarm } from '@tabler/icons-react';
import P2Regular from 'components/typography/P2Regular';
import { differenceInDays } from 'date-fns';
import { useMemo } from 'react';

/**
 * The parameters of the date.
 */
export interface DateDisplayParams {
  date: Date;
  withTime?: boolean;
  withRelative?: boolean;
}

/**
 * Date for displaying date.
 *
 * @see {@link https://www.figma.com/file/M2RU8Nr32l3lDgCCM3PjVL/FM-Point?type=design&node-id=200-2974&mode=dev Figma Design}
 */
export default function DateDisplay({ date, withTime = false, withRelative = false }: DateDisplayParams) {
  const theme = useMantineTheme();

  const formattedDate = useMemo(
    () =>
      date.toLocaleString('sk', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: withTime ? 'numeric' : undefined,
        minute: withTime ? 'numeric' : undefined,
      }),
    [date, withTime]
  );

  const dayA = useMemo(() => new Date(date.getFullYear(), date.getMonth(), date.getDate()), [date]);
  const dayB = useMemo(() => new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()), []);
  const diffDays = useMemo(() => differenceInDays(dayA, dayB), [dayA, dayB]);

  const diff = useMemo(() => {
    if (diffDays === 0) {
      return 'dnes';
    }

    if (diffDays === 1) {
      return 'zajtra';
    }

    if (diffDays === -1) {
      return 'včera';
    }

    if (diffDays >= 2 && diffDays <= 4) {
      return `o ${diffDays} dni`;
    }

    if (diffDays >= 5) {
      return `o ${diffDays} dní`;
    }

    if (diffDays <= -2) {
      return `pred ${-diffDays} dňami`;
    }

    return ''; // This should never happen.
  }, [diffDays]);

  return (
    <Stack
      spacing={0}
      px={8}
      style={{
        border: 'solid 1px',
        borderColor: theme.colors.gray[2],
        borderRadius: rem(4),
        boxShadow: '0px 1px 2px 0px #E2E2E9',
      }}
    >
      <Center>
        <Group noWrap spacing={4}>
          <IconAlarm size={20} />
          <P2Regular align="center">{formattedDate}</P2Regular>
        </Group>
      </Center>
      {withRelative && (
        <P2Regular align="center" color="gray.6">
          {diff}
        </P2Regular>
      )}
    </Stack>
  );
}
