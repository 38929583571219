/**
 * Formatting file sizes.
 *
 * @param {number} sizeInBytes
 * @param {{
 *   maxDecimals?: number
 * }}
 */
export default function formatFileSize(sizeInBytes: number, { maxDecimals = 1 }: { maxDecimals?: number } = {}) {
  const sizes = ['B', 'kB', 'MB', 'GB'];
  let size = sizeInBytes;
  let magnitude = 0;

  while (size >= 1024 && magnitude < sizes.length - 1) {
    size /= 1024;
    magnitude++;
  }

  const decimals = Math.min(maxDecimals, magnitude > 1 && size < 100 ? 1 : 0);

  const number = size.toFixed(decimals);
  const unit = sizes[magnitude];

  return `${number} ${unit}`;
}
