import P2Medium from 'components/typography/P2Medium';
import { SystemExportRow } from 'components/tables/data/system-export/types';
import { useMemo } from 'react';
import formatFileSize from 'utils/format-file-size';

/**
 * Column for the file size.
 */
export default function FileSizeColumn({ data: { fileSize } }: { data: SystemExportRow }) {
  const formattedFileSize = useMemo(() => formatFileSize(fileSize), [fileSize]);
  return <P2Medium color="gray.8">{formattedFileSize}</P2Medium>;
}
