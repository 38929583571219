import { Badge, Box, Button, Group, Tabs, Tooltip } from '@mantine/core';
import DeviceFileTabContent from 'components/forms/device/DeviceFilesTabContent';
import { IconDownload, IconPlus } from '@tabler/icons-react';
import { randomId } from '@mantine/hooks';
import { useEffect, useMemo, useState } from 'react';
import { useAddDeviceFile } from 'components/modals/add-device-file/AddDeviceFileProvider';
import { DeviceFormData } from 'components/forms/device/DeviceForm';
import { UseFormReturnType } from '@mantine/form';
import useLocalStorage from 'hooks/use-local-storage';
import { useFileManager } from 'api/file-manager/file-manager-context';
import useFileDownload from 'api/use-file-download';
import panic from 'errors/panic';
import { FEATURE_TOGGLE_DOWNLOAD_ALL_DEVICE_FILES } from 'env';
import { DEVICE_FILE_TYPE_NAME_MAP, DeviceFileType } from 'model/DeviceFileType';

interface DeviceFilesProps {
  deviceId: number;
  deviceName: string;
  context?: string;
  form?: UseFormReturnType<DeviceFormData>;
  files: DeviceFormData['files'];
  readonly?: boolean;
  initialTab?: DeviceFileType;
}

/**
 * Tab name component for the device files.
 */
function DeviceTab({
  activeTab,
  value,
  files,
}: {
  activeTab: DeviceFileType;
  value: DeviceFileType;
  files: DeviceFormData['files'];
}) {
  const tabName = DEVICE_FILE_TYPE_NAME_MAP[value];
  const isActive = activeTab === value;
  const badgeColor = isActive ? 'blue' : 'gray.5';
  const nFiles = files[value]?.length ?? 0;

  return (
    <Tabs.Tab value={value}>
      <Group spacing={6} align="center">
        <span>{tabName}</span>
        <Badge color={badgeColor} size="sm" variant="filled" mt={2} px={5}>
          <Box pt={1}>{nFiles}</Box>
        </Badge>
      </Group>
    </Tabs.Tab>
  );
}

/**
 * Device files component, used to upload or display files.
 */
export default function DeviceFiles({
  deviceId,
  deviceName,
  context = 'form',
  form,
  files,
  readonly = false,
  initialTab = 'project-documentation',
}: DeviceFilesProps) {
  const { getFileMetadata, createZip } = useFileManager();
  const { addDeviceFile } = useAddDeviceFile();
  const key = useMemo(() => `FMPoint.DeviceFiles.${context}.${deviceId}`, [context, deviceId]);
  const [{ activeTab }, setActiveTab] = useLocalStorage<{ activeTab: DeviceFileType }>(key, { activeTab: initialTab });
  const { download } = useFileDownload();
  const [loading, setLoading] = useState(false);

  /** Adds the file to the device. */
  const addFile = () =>
    addDeviceFile({
      title: DEVICE_FILE_TYPE_NAME_MAP[activeTab!],
      onAdd: (files) =>
        files
          .filter(({ fileId }) => !!fileId)
          .forEach(({ fileId, fileName }, index) => {
            const hasNoPhotos = form!.values.files.photos.filter(({ deleted }) => !deleted).length === 0;
            const isMainImage = activeTab === 'photos' && hasNoPhotos && index === 0;

            form!.insertListItem(`files.${activeTab}`, {
              fileId,
              fileName,
              originalFileName: '',
              uploadedAt: new Date(),
              key: randomId(),
              deleted: false,
              isMainImage,
            });
          }),
    });

  /** Downloads all files */
  const downloadAllFiles = async () => {
    try {
      if (loading) {
        return;
      }

      setLoading(true);

      const filesToDownload = [];

      for (const [tab, filesInTab] of Object.entries(files)) {
        for (const { fileId, fileName } of filesInTab) {
          const metadata = await getFileMetadata({ fileId });

          const fileNameParts = fileName.split('.');
          const fileNameWithoutExtension = fileNameParts.length > 1 ? fileNameParts.slice(0, -1).join('.') : fileName;
          const fileNameWithExtension = `${fileNameWithoutExtension}${metadata.extension}`;

          const tabName = DEVICE_FILE_TYPE_NAME_MAP[tab as DeviceFileType];
          const path = [tabName, fileNameWithExtension].join('/');

          filesToDownload.push({ fileId, path });
        }
      }

      const fileId = await createZip({
        files: filesToDownload,
        fileName: `${deviceId} ${deviceName}`,
      });

      await download(fileId);
    } catch (error: any) {
      panic(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!deviceId && activeTab === 'department') {
      setActiveTab({ activeTab: 'project-documentation' });
    }
  }, [deviceId, activeTab, setActiveTab]);

  return (
    <>
      <Tabs value={activeTab} onTabChange={(activeTab: DeviceFileType) => setActiveTab({ activeTab })}>
        <Tabs.List>
          <DeviceTab files={files} activeTab={activeTab} value="project-documentation" />
          <DeviceTab files={files} activeTab={activeTab} value="revisions" />
          <DeviceTab files={files} activeTab={activeTab} value="photos" />
          <DeviceTab files={files} activeTab={activeTab} value="other" />
          <Tooltip
            withArrow={false}
            openDelay={300}
            multiline
            width={deviceId ? 220 : 285}
            label={
              deviceId
                ? 'Súbory zdieľané medzi všetkými zariadeniami v stredisku'
                : 'Ostatnú dokumentáciu zo strediska bude možné nastaviť po vytvorení zariadenia'
            }
            position="top"
          >
            <DeviceTab files={files} activeTab={activeTab} value="department" />
          </Tooltip>
          {FEATURE_TOGGLE_DOWNLOAD_ALL_DEVICE_FILES && (
            <Box pb={4} pr={4} ml="auto">
              <Button
                size="sm"
                variant="subtle"
                leftIcon={<IconDownload />}
                onClick={downloadAllFiles}
                loading={loading}
              >
                Stiahnuť všetky súbory
              </Button>
            </Box>
          )}
        </Tabs.List>

        <Tabs.Panel value="project-documentation">
          <DeviceFileTabContent tabName="project-documentation" form={form} readonly={readonly} files={files} />
        </Tabs.Panel>
        <Tabs.Panel value="revisions">
          <DeviceFileTabContent tabName="revisions" form={form} readonly={readonly} files={files} />
        </Tabs.Panel>
        <Tabs.Panel value="photos">
          <DeviceFileTabContent tabName="photos" form={form} readonly={readonly} files={files} />
        </Tabs.Panel>
        <Tabs.Panel value="other">
          <DeviceFileTabContent tabName="other" form={form} readonly={readonly} files={files} />
        </Tabs.Panel>
        <Tabs.Panel value="department">
          <DeviceFileTabContent tabName="department" form={form} readonly={readonly} files={files} />
        </Tabs.Panel>
      </Tabs>
      {!readonly && (
        <Button size="md" mt={24} variant="subtle" leftIcon={<IconPlus stroke="1.5" />} onClick={addFile}>
          Pridať súbory
        </Button>
      )}
    </>
  );
}
