import { useApi } from 'api/api-context';
import RevisionTemplatesTable from 'components/tables/data/revision-template/RevisionTemplatesTable';
import DashboardLayout from 'layouts/dashboard/DashboardLayout';
import { ROLE_ADMIN_ID } from 'model/Role';
import { Navigate } from 'react-router-dom';
import { DASHBOARD_PAGE_PATH, SETTINGS_PAGE_PATH } from 'routes/paths';

/**
 * The page for listing all revision templates.
 */
export default function RevisionTemplatesPage() {
  const { roleId } = useApi();

  if (roleId !== ROLE_ADMIN_ID) {
    return <Navigate to={DASHBOARD_PAGE_PATH.original} replace />;
  }

  return (
    <DashboardLayout
      height="screen"
      breadcrumbs={[{ title: 'Nastavenia', link: SETTINGS_PAGE_PATH.original }, { title: 'Šablóny revíznych správ' }]}
    >
      <RevisionTemplatesTable />
    </DashboardLayout>
  );
}
