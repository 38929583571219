import { useDisclosure } from '@mantine/hooks';
import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import AddDeviceFileModal, { AddDeviceFileModalParams } from 'components/modals/add-device-file/AddDeviceFileModal';

/** Parameters of the addDeviceFile function. */
type AddDeviceFileParams = Omit<AddDeviceFileModalParams, 'opened' | 'onClose'>;

/** Function for adding the file to the device. */
type AddDeviceFile = (params: AddDeviceFileParams) => void;

/** Interface of the AddDeviceFileContext. */
interface IAddDeviceFileContext {
  addDeviceFile: AddDeviceFile;
}

/**
 * Context for adding the file to the device.
 */
const AddDeviceFileContext = createContext<IAddDeviceFileContext>(undefined!);

/**
 * Provider for adding the file to the device.
 */
export function AddDeviceFileProvider({ children }: { children: React.ReactNode }) {
  const [opened, { close, open }] = useDisclosure(false);
  const [params, setParams] = useState<AddDeviceFileParams>({});

  const addDeviceFile: AddDeviceFile = useCallback(
    (params) => {
      setParams(params);
      open();
    },
    [open]
  );

  const value = useMemo(() => ({ addDeviceFile }), [addDeviceFile]);

  return (
    <AddDeviceFileContext.Provider value={value}>
      {children}
      <AddDeviceFileModal opened={opened} onClose={close} {...params} />
    </AddDeviceFileContext.Provider>
  );
}

/**
 * Hook for adding file to the device.
 */
export function useAddDeviceFile() {
  return useContext(AddDeviceFileContext);
}
