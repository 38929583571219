import { CSSProperties, useCallback, useEffect, useState } from 'react';
import panic from 'errors/panic';
import toBase64 from 'utils/to-base64';
import { noop } from 'lodash';
import UploadFilesList from 'components/files/upload/UploadFilesList';
import DropFiles from 'components/files/upload/DropFiles';
import { Stack, SystemProp } from '@mantine/core';
import { FILE_UPLOAD_CHUNK_SIZE } from 'env';
import type { IFile, IFileError, IFileState } from 'components/files/upload/types';
import { useFileManager } from 'api/file-manager/file-manager-context';

/**
 * Upload files component.
 */
export default function UploadFiles({
  accept = ['*'],
  maxFiles = Infinity,
  onChange = noop,
  onFileUploaded = noop,
  error = '',
  initialFiles = [],
  initialErrors = [],
  loading = false,
  placeholderCount = 0,
  hideUploadedFiles = false,
  listMaxHeight = Infinity,
}: {
  accept?: string[];
  maxFiles?: number;
  onChange?: (state: IFileState[] | undefined) => void;
  onFileUploaded?: (fileId: string) => void;
  error?: string;
  initialFiles?: IFileState[];
  initialErrors?: IFileError[];
  loading?: boolean;
  placeholderCount?: number;
  hideUploadedFiles?: boolean;
  listMaxHeight?: SystemProp<CSSProperties['maxHeight']>;
} = {}) {
  const [files, setFiles] = useState<IFileState[]>(initialFiles);
  const { initLargeFile, uploadLargeFile, finalizeLargeFile } = useFileManager();

  /** Updates a file in the list. */
  const updateFile = useCallback(
    (uuid: string, partial: Partial<IFileState>) =>
      setFiles((files) => files.map((file) => (file.uuid === uuid ? { ...file, ...partial } : file))),
    [setFiles]
  );

  /** Deletes a file from the list. */
  const deleteFile = useCallback(
    (uuid: string) => setFiles((files) => files.filter((file) => file.uuid !== uuid)),
    [setFiles]
  );

  /** Changes the file name. */
  const changeFileName = useCallback((uuid: string, fileName: string) => updateFile(uuid, { fileName }), [updateFile]);

  /** Adds files to the list. */
  const addFiles = useCallback((files: IFileState[]) => setFiles((curr) => [...curr, ...files]), [setFiles]);

  /** Handles file acceptance by file drop. */
  const onAccept = async (files: IFile[]) => {
    addFiles(
      files.map(({ uuid, file }) => ({
        uuid,
        progress: 0,
        finalized: false,
        fileName: file.name,
        fileType: file.type,
      }))
    );

    // Upload valid files.
    for (const { uuid, file } of files) {
      try {
        const fileId = await initLargeFile({ fileName: file.name });

        updateFile(uuid, { fileId });

        let offset = 0;
        while (offset < file.size) {
          const chunk = file.slice(offset, offset + FILE_UPLOAD_CHUNK_SIZE);
          const contents = await toBase64(chunk);

          await uploadLargeFile({ fileId, contents });

          offset += chunk.size;

          updateFile(uuid, { progress: offset / file.size });
        }

        await finalizeLargeFile({ fileId });

        updateFile(uuid, { progress: 1, finalized: true });
        onFileUploaded(fileId);
      } catch (error) {
        panic(error as Error);
      }
    }
  };

  // Propagate changes to parent component.
  useEffect(() => {
    onChange(files);
  }, [files]);

  return (
    <Stack spacing={16} w={500}>
      <DropFiles
        accept={accept}
        onAccept={onAccept}
        maxFiles={maxFiles - files.length}
        disabled={files.length >= maxFiles}
        error={error || undefined}
        withErrorList
        initialErrors={initialErrors}
      />
      <UploadFilesList
        files={files}
        onDelete={deleteFile}
        onFileNameChange={changeFileName}
        loading={loading}
        placeholderCount={placeholderCount}
        hideUploadedFiles={hideUploadedFiles}
        maxHeight={listMaxHeight}
      />
    </Stack>
  );
}
