import { randomId, useCounter } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { DeviceGetResponse } from 'api/actions/device-get/device-get-response';
import { useApi } from 'api/api-context';
import { DeviceEvidenceFieldType } from 'components/forms/device/DeviceEvidenceField';
import DeviceForm, { DeviceFormData } from 'components/forms/device/DeviceForm';
import { stringifyDate } from 'components/inputs/DateInput';
import { addMonths } from 'date-fns';
import { DEVICE_REDIRECT_AFTER_SAVE } from 'env';
import panic from 'errors/panic';
import DashboardLayout from 'layouts/dashboard/DashboardLayout';
import { useEffect, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { DEVICES_PAGE_PATH } from 'routes/paths';
import { SUCCESS_NOTIFICATION_COLOR } from 'utils/constants';

/**
 * Page used to edit a device.
 *
 * - {@link https://www.figma.com/file/M2RU8Nr32l3lDgCCM3PjVL/FM-Point?node-id=256%3A12465 Figma Design}
 * - {@link https://www.notion.so/Device-Edit-Device-8770774301454456a16908130708793e?pvs=4 Notion Page}
 */
export default function EditDevicePage() {
  const navigate = useNavigate();
  const { deviceId } = useParams();
  const [refreshToken, { increment: forceRefresh }] = useCounter(0);
  const { getAction } = useApi();
  const [device, setDevice] = useState<DeviceGetResponse | null>(null);
  const [canEdit, setCanEdit] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (deviceId) {
      const deviceGet = getAction('DeviceGet');
      deviceGet({ parameters: { deviceId } }).then(setDevice).catch(panic);
    }
  }, [deviceId, refreshToken]);

  useEffect(() => {
    if (device) {
      const hasPermission = getAction('AuthUserHasPermissionInDepartment');
      hasPermission({
        parameters: { departmentId: String(device.department.departmentId), permissionSlug: 'manage-devices' },
      })
        .then(({ hasPermission }) => {
          setCanEdit(hasPermission);
          setLoading(false);
        })
        .catch(panic);
    }
  }, [device]);

  if (!deviceId) {
    return <Navigate to={DEVICES_PAGE_PATH.original} />;
  }

  /**
   * Handles the form submission.
   */
  function onSubmit({
    deviceName,
    manufacturer,
    serialNumber,
    manufactured,
    internalPossessionsNumber,
    internalNote,
    building,
    floor,
    room,
    longitude,
    latitude,
    deviceTypeId,
    deviceSubtypeId,
    responsiblePersonId,
    responsiblePersonNote,
    nextRevision,
    files,
    faults,
    evidenceFields,
  }: DeviceFormData) {
    const deviceUpdateAction = getAction('DeviceUpdate');

    const mappedFiles = Object.entries(files).map(([key, value]) => {
      return value.map((val) => ({
        deviceFileId: val.deviceFileId ?? undefined,
        fileName: val.fileName,
        fileId: val.fileId,
        fileType: key,
        deleted: val.deleted,
        isMainImage: val.isMainImage,
      }));
    });

    const mappedRevisions = nextRevision
      .filter(({ description, revisionPeriod }) => Number(revisionPeriod) !== 0 && description !== '')
      .map((revision) => {
        let date: string | undefined = '';

        if (revision.neverExecuted) {
          date = revision.nextDate !== '' ? revision.nextDate! : undefined;
        } else {
          date = revision.date !== '' ? revision.nextDate! : undefined;
        }

        return {
          revisionPlanId: revision.revisionPlanId ?? undefined,
          description: revision.description,
          date,
          revisionPeriod: Number(revision.revisionPeriod),
          deleted: revision.deleted,
          neverExecuted: revision.neverExecuted,
          deviceTypeId: revision.deviceTypeId ? Number(revision.deviceTypeId) : undefined,
        };
      });

    const mappedFaults = faults
      .filter(({ faultName }) => faultName !== '')
      .map(({ faultId, faultName, faultSeverityId, description, deleted }) => ({
        faultId: faultId ?? undefined,
        faultName,
        faultSeverityId: Number(faultSeverityId),
        description: description ?? '',
        deleted,
      }));

    return deviceUpdateAction({
      parameters: {
        deviceId: deviceId!,
      },
      payload: {
        deviceName,
        manufacturer: manufacturer === '' ? undefined : manufacturer,
        serialNumber: serialNumber === '' ? undefined : serialNumber,
        manufactured: String(manufactured) === '' ? undefined : manufactured,
        internalPossessionsNumber,
        internalNote,
        building,
        floor,
        room,
        longitude,
        latitude,
        deviceTypeId: Number(deviceTypeId),
        deviceSubtypeId: Number(deviceSubtypeId),
        responsiblePersonId: responsiblePersonId ? Number(responsiblePersonId) : undefined,
        responsiblePersonNote,
        nextRevision: mappedRevisions,
        files: mappedFiles.flat(),
        faults: mappedFaults,
        evidenceFields: Object.values(evidenceFields),
      },
    })
      .then(() => {
        notifications.show({
          title: 'Zariadenie bolo úspešne upravené',
          message: `Zariadenie ${deviceName} bolo úspešne upravené.`,
          color: SUCCESS_NOTIFICATION_COLOR,
        });

        if (DEVICE_REDIRECT_AFTER_SAVE === 'list') {
          navigate(DEVICES_PAGE_PATH.original);
        } else {
          forceRefresh();
        }
      })
      .catch(panic);
  }

  /**
   * Retrieves the files for the given tab name.
   */
  function retrieveDeviceFiles(files: DeviceGetResponse['files'], tabName: string) {
    return files
      ?.filter((file) => file.fileType === tabName)
      .map((file) => ({
        deviceFileId: file.deviceFileId,
        fileName: file.fileName,
        fileId: file.fileId,
        key: randomId(),
        uploadedAt: new Date(file.created!),
        originalFileName: '',
        deleted: false,
        isMainImage: file.isMainImage,
      }));
  }

  return (
    <DashboardLayout
      title={`${canEdit ? 'Úprava zariadenia: ' : 'Zariadenie: '}${device?.deviceName ?? ''}`}
      titleColor={`${loading ? '' : device?.status ? '' : 'red.9'}`}
      isDiscarded={!loading && !device?.status}
      breadcrumbs={[
        { title: 'Zariadenia', link: DEVICES_PAGE_PATH.original },
        { title: device?.deviceName ?? 'Načítavanie ...' },
      ]}
    >
      {device && (
        <DeviceForm
          key={`DeviceForm-${device.updated}`}
          initialValues={{
            deviceName: device.deviceName,
            manufacturer: device.manufacturer ?? '',
            serialNumber: device.serialNumber ?? '',
            manufactured: device.manufactured ?? undefined,
            internalPossessionsNumber: device.internalPossessionsNumber ?? '',
            internalNote: device.internalNote ?? '',
            building: device.building ?? '',
            floor: device.floor ?? '',
            room: device.room ?? '',
            longitude: device.longitude ? parseFloat(device.longitude) : undefined,
            latitude: device.latitude ? parseFloat(device.latitude) : undefined,
            deviceTypeId: String(device.deviceType.deviceTypeId),
            deviceSubtypeId: String(device.deviceSubtype.deviceTypeId ?? 0),
            departmentId: String(device.department.departmentId),
            responsiblePersonId: device.responsiblePerson ? String(device.responsiblePerson.userId) : '',
            responsiblePersonNote: device.responsiblePersonNote ?? '',
            externalId: device.externalId ?? '',
            files: {
              'project-documentation': retrieveDeviceFiles(device.files, 'project-documentation') ?? [],
              revisions: retrieveDeviceFiles(device.files, 'revisions') ?? [],
              photos: retrieveDeviceFiles(device.files, 'photos') ?? [],
              other: retrieveDeviceFiles(device.files, 'other') ?? [],
              department: retrieveDeviceFiles(device.files, 'department') ?? [],
            },
            nextRevision: device.nextRevision
              ? device.nextRevision.map(
                  ({ revisionPlanId, description, date, revisionPeriod, externalId, neverExecuted, deviceTypeId }) => {
                    let parsedDate = '';
                    let parsedNextDate = '';

                    if (date) {
                      try {
                        parsedDate = stringifyDate(addMonths(new Date(date), -revisionPeriod));
                        parsedNextDate = stringifyDate(new Date(date));
                      } catch (error: any) {
                        panic(new Error(`Invalid date: ${date}`));
                      }
                    }

                    return {
                      revisionPlanId,
                      description,
                      date: neverExecuted ? '' : parsedDate,
                      nextDate: parsedNextDate,
                      revisionPeriod: String(revisionPeriod),
                      key: randomId(),
                      externalId,
                      deleted: false,
                      neverExecuted,
                      deviceTypeId: deviceTypeId ? String(deviceTypeId) : '',
                    };
                  }
                )
              : [
                  {
                    description: '',
                    date: '',
                    revisionPeriod: '',
                    key: randomId(),
                    deleted: false,
                    neverExecuted: false,
                  },
                ],
            faults: device.faults
              ? device.faults.map(({ faultId, faultName, faultSeverityId, description, fixed }) => ({
                  faultId,
                  faultName,
                  faultSeverityId: String(faultSeverityId),
                  description,
                  fixed: { fixedAt: fixed?.fixedAt, fixedNote: fixed?.fixedNote },
                  key: randomId(),
                  deleted: false,
                }))
              : [],
            evidenceFields: device.evidenceFields.reduce((acc, field) => {
              // the API returns string for decimal numbers
              if (field.fieldType === 'number') {
                field.fieldNumberValue = Number(field.fieldNumberValue);
              }

              acc[field.evidenceFieldId!] = field;

              return acc;
            }, {} as { [evidenceFieldId: number]: DeviceEvidenceFieldType }),
          }}
          onSubmit={onSubmit}
          readOnly={{
            departmentId: true,
            // readonly so that the first time the edit page is rendered, the deviceSubType is not changed
            deviceSubtypeId: true,
            responsiblePersonId: true,
            deviceName: !canEdit,
            manufacturer: !canEdit,
            serialNumber: !canEdit,
            manufactured: !canEdit,
            internalPossessionsNumber: !canEdit,
            internalNote: !canEdit,
            building: !canEdit,
            floor: !canEdit,
            room: !canEdit,
            longitude: !canEdit,
            latitude: !canEdit,
            deviceTypeId: !canEdit,
            responsiblePersonNote: !canEdit,
            externalId: !canEdit,
            nextRevision: !canEdit,
            faults: !canEdit,
            files: !canEdit,
          }}
          hideFooter={!canEdit}
        />
      )}
    </DashboardLayout>
  );
}
