import { times } from 'lodash';

type Task = () => Promise<void>;

/** Runs tasks in parallel with a given parallelism. */
export default class ParallelTaskRunner {
  private parallelism;
  private tasks: Task[] = [];

  /** Number of tasks in the runner. */
  get length() {
    return this.tasks.length;
  }

  /** Creates a new parallel task runner. */
  constructor(parallelism: number) {
    this.parallelism = parallelism;
  }

  /** Adds a task to the runner. */
  add(task: Task) {
    this.tasks.push(task);
  }

  /** Runs all tasks in parallel. */
  async run() {
    await Promise.all(times(this.parallelism, () => this.createThread()));
  }

  /** Runs a single thread. */
  private async createThread() {
    while (this.tasks.length > 0) {
      const task = this.tasks.shift()!;
      await task();
    }
  }
}
