import { Button, Group, Modal, Progress, Stack } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useApi } from 'api/api-context';
import { useFileManager } from 'api/file-manager/file-manager-context';
import useFileDownload from 'api/use-file-download';
import { OrganizationSelect } from 'components/selects/OrganizationSelect';
import Toast from 'components/Toast';
import P2Regular from 'components/typography/P2Regular';
import P3Medium from 'components/typography/P3Medium';
import { SYSTEM_EXPORT_PARALLELISM } from 'env';
import panic from 'errors/panic';
import { noop } from 'lodash';
import { DEVICE_FILE_TYPE_NAME_MAP, DeviceFileType } from 'model/DeviceFileType';
import { useMemo, useRef, useState } from 'react';
import ParallelTaskRunner from 'utils/parallel-task-runner';
import UserTable from 'components/tables/data/user/UserTable';
import DepartmentTable from 'components/tables/data/department/DepartmentTable';
import DeviceTable from 'components/tables/data/device/DeviceTable';
import RevisionPlanTable from 'components/tables/data/revision-plan/RevisionPlanTable';
import FaultTable from 'components/tables/data/fault/FaultTable';
import RevisionTable from 'components/tables/data/revision/RevisionTable';
import { IDataTablePublic } from 'components/tables/DataTable';
import { nanoid } from 'nanoid';

interface SystemExportModalProps {
  opened: boolean;
  onClose: () => void;
  onExport?: (systemExportId: number) => void;
}

const MIME_TYPE_XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

/**
 * Modal for performing system export.
 */
export default function SystemExportModal({ opened, onClose, onExport = noop }: SystemExportModalProps) {
  const { createZip, getFileMetadata, uploadFile } = useFileManager();
  const { download } = useFileDownload();
  const { getAction } = useApi();
  const [loading, { open: startLoading, close: stopLoading }] = useDisclosure(false);
  const [organizationId, setOrganizationId] = useState<string | null>('');
  const [step, setStep] = useState(0);
  const [steps, setSteps] = useState(0);
  const [message, setMessage] = useState('');

  const userTableRef = useRef<IDataTablePublic>(null);
  const departmentTableRef = useRef<IDataTablePublic>(null);
  const deviceTableRef = useRef<IDataTablePublic>(null);
  const revisionPlanTableRef = useRef<IDataTablePublic>(null);
  const faultTableRef = useRef<IDataTablePublic>(null);
  const revisionTableRef = useRef<IDataTablePublic>(null);

  /** Handles system export. */
  const handleExport = async () => {
    try {
      if (!organizationId) {
        return;
      }

      startLoading();
      setMessage('Sťahujem zoznam zariadení a revíznych správ');

      const listDevices = getAction('DeviceList');
      const listRevisions = getAction('RevisionList');
      const getDevice = getAction('DeviceGet');
      const getOrganization = getAction('OrganizationGet');
      const createSystemExport = getAction('SystemExportCreate');

      const runner = new ParallelTaskRunner(SYSTEM_EXPORT_PARALLELISM);
      const files: { fileId: string; path: string }[] = [];

      const [{ organizationName }, devices, revisions] = await Promise.all([
        getOrganization({ parameters: { organizationId } }),
        listDevices({ query: { filters: { 'organizationId.eq': organizationId } } }),
        listRevisions({ query: { filters: { 'customerOrganizationId.eq': organizationId } } }),
      ]);

      setMessage('Sťahujem súbory zariadení a revízne správy');

      // Export device files
      for (const { deviceId, department } of devices) {
        runner.add(async () => {
          const device = await getDevice({ parameters: { deviceId: String(deviceId) } });

          for (const { fileId, fileType, fileName } of device.files ?? []) {
            if (fileType !== 'department') {
              const metadata = await getFileMetadata({ fileId });

              const fileNameParts = fileName.split('.');

              const fileNameWithoutExtension =
                fileNameParts.length > 1 ? fileNameParts.slice(0, -1).join('.') : fileName;

              const fileNameWithExtension = `${fileNameWithoutExtension}${metadata.extension}`;

              const path = [
                department.departmentName,
                'Zariadenia',
                device.deviceType.deviceTypeName,
                device.deviceSubtype.deviceTypeName,
                `${deviceId}: ${device.deviceName}`,
                DEVICE_FILE_TYPE_NAME_MAP[fileType as DeviceFileType],
                fileNameWithExtension,
              ]
                .map((part) => part.replace(/\//g, ''))
                .join('/');

              files.push({ fileId, path });
            }
          }

          setStep((prev) => prev + 1);
        });
      }

      // Export revision files
      for (const { revisionId, revisionName, fileId, signedFileId, department } of revisions) {
        const exportFileId = signedFileId || fileId;

        if (exportFileId) {
          runner.add(async () => {
            const metadata = await getFileMetadata({ fileId: exportFileId });

            files.push({
              fileId: exportFileId,
              path: [department.departmentName, 'Revízne správy', `${revisionId}: ${revisionName}`, metadata.basename]
                .map((part) => part.replace(/\//g, ''))
                .join('/'),
            });

            setStep((prev) => prev + 1);
          });
        }
      }

      setSteps(runner.length + 6);
      await runner.run();

      setMessage('Sťahujem zoznam používateľov');
      const usersFileId = await uploadFile({
        fileName: `${nanoid()}.xlsx`,
        contents: `data:${MIME_TYPE_XLSX};base64,${userTableRef.current?.getDataAsXlsx()}`,
      });
      files.push({ fileId: usersFileId, path: 'Používatelia.xlsx' });
      setStep((prev) => prev + 1);

      setMessage('Sťahujem zoznam stredísk');
      const departmentsFileId = await uploadFile({
        fileName: `${nanoid()}.xlsx`,
        contents: `data:${MIME_TYPE_XLSX};base64,${departmentTableRef.current?.getDataAsXlsx()}`,
      });
      files.push({ fileId: departmentsFileId, path: 'Strediská.xlsx' });
      setStep((prev) => prev + 1);

      setMessage('Sťahujem zoznam zariadení');
      const devicesFileId = await uploadFile({
        fileName: `${nanoid()}.xlsx`,
        contents: `data:${MIME_TYPE_XLSX};base64,${deviceTableRef.current?.getDataAsXlsx()}`,
      });
      files.push({ fileId: devicesFileId, path: 'Zariadenia.xlsx' });
      setStep((prev) => prev + 1);

      setMessage('Sťahujem zoznam termínov');
      const revisionPlansFileId = await uploadFile({
        fileName: `${nanoid()}.xlsx`,
        contents: `data:${MIME_TYPE_XLSX};base64,${revisionPlanTableRef.current?.getDataAsXlsx()}`,
      });
      files.push({ fileId: revisionPlansFileId, path: 'Termíny.xlsx' });
      setStep((prev) => prev + 1);

      setMessage('Sťahujem zoznam závad');
      const faultsFileId = await uploadFile({
        fileName: `${nanoid()}.xlsx`,
        contents: `data:${MIME_TYPE_XLSX};base64,${faultTableRef.current?.getDataAsXlsx()}`,
      });
      files.push({ fileId: faultsFileId, path: 'Závady.xlsx' });
      setStep((prev) => prev + 1);

      setMessage('Sťahujem zoznam revízií');
      const revisionsFileId = await uploadFile({
        fileName: `${nanoid()}.xlsx`,
        contents: `data:${MIME_TYPE_XLSX};base64,${revisionTableRef.current?.getDataAsXlsx()}`,
      });
      files.push({ fileId: revisionsFileId, path: 'Revízie.xlsx' });
      setStep((prev) => prev + 1);

      setMessage('Vytváram ZIP súbor');
      const dateStr = new Date().toISOString().slice(0, 10);
      const fileName = `${organizationName} (${dateStr})`;
      const zipFileId = await createZip({ fileName, files });

      setMessage('Vytváram záznam exportu');
      const { systemExportId } = await createSystemExport({
        payload: { organizationId: Number(organizationId), fileId: zipFileId },
      });
      onExport(systemExportId);

      setMessage('Sťahujem ZIP súbor');
      await download(zipFileId);
    } catch (error: any) {
      panic(error);
    } finally {
      stopLoading();
      onClose();
      setStep(0);
      setSteps(0);
      setOrganizationId(null);
      setMessage('');
    }
  };

  const dataFilters = useMemo(
    () => ({ 'organization.organizationId': { filterType: 'text', type: 'equals', filter: organizationId } }),
    [organizationId]
  );

  return (
    <Modal
      title="Systémový export"
      opened={opened}
      onClose={onClose}
      closeOnClickOutside={!loading}
      closeOnEscape={!loading}
      withCloseButton={!loading}
      size={500}
      centered
    >
      {loading && (
        <Stack display="none">
          <UserTable
            ref={userTableRef}
            suppressSaveProfiles
            suppressProfileChangedNotification
            initialFilters={dataFilters}
          />
          <DepartmentTable
            ref={departmentTableRef}
            suppressSaveProfiles
            suppressProfileChangedNotification
            initialFilters={dataFilters}
          />
          <DeviceTable
            ref={deviceTableRef}
            suppressSaveProfiles
            suppressProfileChangedNotification
            initialFilters={dataFilters}
          />
          <RevisionPlanTable
            ref={revisionPlanTableRef}
            suppressSaveProfiles
            suppressProfileChangedNotification
            initialFilters={dataFilters}
          />
          <FaultTable
            ref={faultTableRef}
            suppressSaveProfiles
            suppressProfileChangedNotification
            initialFilters={dataFilters}
          />
          <RevisionTable
            ref={revisionTableRef}
            suppressSaveProfiles
            suppressProfileChangedNotification
            initialFilters={dataFilters}
          />
        </Stack>
      )}
      {loading ? (
        <Stack spacing={4} pt={16}>
          <P2Regular>{message} &hellip;</P2Regular>
          <Group spacing={8} noWrap>
            <Progress w="100%" value={(step / steps) * 100} />
            <P3Medium>
              {step}&nbsp;/&nbsp;{steps}
            </P3Medium>
          </Group>
        </Stack>
      ) : (
        <Stack spacing={16} pt={16}>
          <Toast
            type="info"
            message="Systémový export umožňuje exportovať všetky zariadenia a revízne správy zvolenej organizácie."
            withCloseButton={false}
          />
          <OrganizationSelect
            label="Vyberte organizáciu"
            value={organizationId}
            onChange={setOrganizationId}
            autoSelectSingleResult
            showDiscarded={false}
          />
        </Stack>
      )}
      <Group position="right" spacing={16} pt={32}>
        <Button variant="link" onClick={onClose}>
          Zrušiť
        </Button>
        <Button variant="primary" onClick={loading ? noop : handleExport} disabled={!organizationId} loading={loading}>
          Spustiť export
        </Button>
      </Group>
    </Modal>
  );
}
