import { useApi } from 'api/api-context';
import axios from 'axios';
import Qs from 'qs';
import { FileManagerContext } from 'api/file-manager/file-manager-context';
import { useMemo } from 'react';
import { FILE_MANAGER_TIMEOUT, FILE_MANAGER_URL, FILE_UPLOAD_MAX_SIZE } from 'env';
import formatFileSize from 'utils/format-file-size';

/**
 * File Manager Provider.
 */
export default function FileManagerProvider({ children }: { children: React.ReactNode }) {
  const { jwt } = useApi();

  /**
   * The connector object = axios instance.
   */
  const value = useMemo(() => {
    const connector = axios.create({
      timeout: FILE_MANAGER_TIMEOUT,
      baseURL: FILE_MANAGER_URL,
    });

    connector.interceptors.request.use((cfg) => {
      // Add JWT to the request.
      if (jwt && !cfg.headers.Authorization) {
        cfg.headers.Authorization = `Bearer ${jwt}`;
      }

      // Serialize deep objects.
      cfg.paramsSerializer = (params) => Qs.stringify(params, { arrayFormat: 'brackets', encode: false });

      return cfg;
    });

    /** Uploads the file and returns its ID. */
    const uploadFile = async ({
      fileName,
      contents,
    }: {
      fileName: string;
      contents: string | ArrayBuffer | null;
    }): Promise<string> => {
      try {
        const { data } = await connector.post('/file/upload', { fileName, contents });
        return data.fileId;
      } catch (e: any) {
        if (e?.response?.status === 413) {
          const formattedMaxFileSize = formatFileSize(FILE_UPLOAD_MAX_SIZE, { maxDecimals: 0 });
          throw new Error(`Súbor je príliš veľký. Maximálna veľkosť súboru je ${formattedMaxFileSize}`);
        }

        throw e;
      }
    };

    /** Retrieves the contents of the file. */
    const readFile = async ({ fileId }: { fileId: string }): Promise<Blob> => {
      const { data } = await connector.get(`/file/${fileId}`, { responseType: 'blob' });
      return data;
    };

    /** Retrieves the metadata of the file. */
    const getFileMetadata = async ({ fileId }: { fileId: string }) => {
      const { data } = await connector.get(`/file/${fileId}/metadata`);
      return data;
    };

    /** Retrieves the thumbnail of the file. */
    const getFileThumbnail = async ({ fileId }: { fileId: string }) => {
      const { data } = await connector.get(`/file/${fileId}/thumbnail`, { responseType: 'blob' });
      return data;
    };

    /** Creates an access token for the file. */
    const createAccessToken = async ({ fileId }: { fileId: string }) => {
      const { data } = await connector.post(`/file/${fileId}/create-access-token`);
      return data.token;
    };

    /** Initializes a large file upload. Returns the ID of the file. */
    const initLargeFile = async ({ fileName }: { fileName: string }) => {
      const { data } = await connector.post('/file/large/init', { fileName });
      return data.fileId;
    };

    /** Uploads a chunk of the large file. */
    const uploadLargeFile = async ({ fileId, contents }: { fileId: string; contents: string | ArrayBuffer | null }) => {
      await connector.post(`/file/large/${fileId}/upload`, { contents });
    };

    /** Finalizes the large file upload. */
    const finalizeLargeFile = async ({ fileId }: { fileId: string }) => {
      await connector.post(`/file/large/${fileId}/finalize`);
    };

    /** Creates a ZIP archive from the files. */
    const createZip = async ({ fileName, files }: { fileName: string; files: { fileId: string; path: string }[] }) => {
      const { data } = await connector.post('/file/zip/create', { fileName, files });
      return data.fileId;
    };

    return {
      uploadFile,
      readFile,
      getFileMetadata,
      getFileThumbnail,
      createAccessToken,
      initLargeFile,
      uploadLargeFile,
      finalizeLargeFile,
      createZip,
    };
  }, [jwt]);

  return <FileManagerContext.Provider value={value}>{children}</FileManagerContext.Provider>;
}
