import { MantineThemeOverride, rem } from '@mantine/core';
import { H2Medium, P1Regular } from 'theme/typography';

export type ModalTheme = NonNullable<MantineThemeOverride['components']>['Modal'];

export const Modal: ModalTheme = {
  defaultProps: {
    radius: 4,
  },
  styles: (theme) => ({
    overlay: {
      opacity: 0.4,
      color: theme.colors.gray[9],
    },
    header: {
      paddingTop: rem(24),
      paddingRight: rem(24),
      paddingLeft: rem(24),
      paddingBottom: rem(12),
    },
    title: {
      ...H2Medium,
    },
    body: {
      ...P1Regular,
      color: theme.colors.gray[7],
      padding: rem(24),
    },
    close: {
      color: theme.colors.gray[7],
      padding: rem(0),
      svg: {
        width: rem(24),
        height: rem(24),
      },
    },
  }),
};
