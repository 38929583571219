import { Box, Center, Group, LoadingOverlay, Stack } from '@mantine/core';
import { RevisionGetResponse } from 'api/actions/revision-get/revision-get-response';
import RevisionStatusIcon from 'components/RevisionStatusIcon';
import P2Medium from 'components/typography/P2Medium';
import P2Regular from 'components/typography/P2Regular';
import P4Regular from 'components/typography/P4Regular';
import { useFillOutRevisionDataProvider } from 'components/forms/revision/fill-out/data/FillOutRevisionDataProvider';
import { useMemo } from 'react';
import QuestionMarkTooltip from 'components/QuestionMarkTooltip';

interface RevisionDetailsBoxProps {
  revision: RevisionGetResponse | null;
  loading?: boolean;
}

/**
 * Displays the details of a device in a row.
 */
function DeviceRow({ deviceName, deviceId }: { deviceName: string; deviceId: number }) {
  const { revisionHasDevice } = useFillOutRevisionDataProvider();
  const hasDevice = useMemo(() => revisionHasDevice(deviceId), [deviceId, revisionHasDevice]);

  return (
    <Group spacing={4} noWrap className={hasDevice ? '' : 'line-through'}>
      <P2Regular color={hasDevice ? 'gray.7' : 'gray.5'}>{deviceName}</P2Regular>
      <P4Regular color={hasDevice ? 'gray.7' : 'gray.5'}>(ID: {deviceId})</P4Regular>
      {!hasDevice && (
        <Center pl={4}>
          <QuestionMarkTooltip size={14} label="Zariadenie sa nenachádza v aktuálnej revíznej správe" />
        </Center>
      )}
    </Group>
  );
}

/**
 * Displays the details of a revision in a box.
 */
export default function RevisionDetailsBox({ revision, loading = false }: RevisionDetailsBoxProps) {
  if (!revision) {
    return (
      <Stack spacing={8} bg="blue.0" p={8} className="rounded-lg" pos="relative">
        <LoadingOverlay visible />
        <Box h={60} />
      </Stack>
    );
  }

  return (
    <Stack spacing={8} bg="blue.0" p={8} className="rounded-lg" pos="relative">
      <LoadingOverlay visible={loading} />
      <Box className="grid grid-cols-[auto,1fr] gap-x-4 gap-y-1">
        <P2Medium>ID</P2Medium>
        <P2Regular>{revision.revisionId}</P2Regular>
        <P2Medium>Názov</P2Medium>
        <P2Regular>{revision.revisionName}</P2Regular>
        <P2Medium>Organizácia</P2Medium>
        <P2Regular>{revision.organization.fullName}</P2Regular>
        <P2Medium>Stredisko</P2Medium>
        <P2Regular>{revision.department.fullName}</P2Regular>
        <P2Medium>Stav</P2Medium>
        <RevisionStatusIcon statusId={revision.revisionStatus.revisionStatusId} />
        <P2Medium>Zariadenia</P2Medium>
        <Stack spacing={4}>
          {revision.devices.map(({ deviceName, deviceId }) => (
            <DeviceRow key={deviceId} deviceName={deviceName} deviceId={deviceId} />
          ))}
        </Stack>
      </Box>
    </Stack>
  );
}
