import DeviceTable from 'components/tables/data/device/DeviceTable';
import DashboardLayout from 'layouts/dashboard/DashboardLayout';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

/**
 * Page used to display devices.
 *
 * - {@link https://www.figma.com/file/M2RU8Nr32l3lDgCCM3PjVL/FM-Point?node-id=252%3A9379 Figma Design}
 * - {@link https://www.notion.so/Device-List-Devices-98f2a6f387f148258bda1094af96cc47?pvs=4 Notion Page}
 */
export default function DevicesPage() {
  const [searchParams, setSearchParams] = useSearchParams();

  const [initialFilters] = useState(() => {
    const filters: Record<string, any> = {};

    if (searchParams.has('organizationId')) {
      filters['organization.organizationId'] = {
        filterType: 'text',
        type: 'equals',
        filter: searchParams.get('organizationId'),
      };
    }

    if (searchParams.has('departmentId')) {
      filters['department.departmentId'] = {
        filterType: 'text',
        type: 'equals',
        filter: searchParams.get('departmentId'),
      };
    }

    if (searchParams.has('deviceTypeId')) {
      filters['deviceType.deviceTypeId'] = {
        filterType: 'text',
        type: 'equals',
        filter: searchParams.get('deviceTypeId'),
      };
    }

    if (searchParams.has('faultType')) {
      filters.faultType = {
        filterType: 'text',
        type: 'equals',
        filter: searchParams.get('faultType'),
      };
    }

    if (searchParams.has('importId')) {
      filters.importId = {
        filterType: 'text',
        type: 'equals',
        filter: searchParams.get('importId'),
      };
    }

    return filters;
  });

  useEffect(() => {
    setSearchParams(
      (params) => {
        params.delete('organizationId');
        params.delete('departmentId');
        params.delete('deviceTypeId');
        params.delete('faultType');
        params.delete('importId');
        return params;
      },
      { replace: true }
    );
  }, []);

  return (
    <DashboardLayout height="screen" breadcrumbs={[{ title: 'Zariadenia' }]}>
      <DeviceTable initialFilters={initialFilters} />
    </DashboardLayout>
  );
}
