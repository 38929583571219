/** @typedef {import('components/files/upload/types').IFile} IFile */
/** @typedef {import('components/files/upload/types').IFileError} IFileError */
/** @typedef {import('components/files/upload/types').IFileRejectionReason} IFileRejectionReason */

import { Button, Group, Stack, Text, useMantineTheme } from '@mantine/core';
import { Dropzone } from '@mantine/dropzone';
import matchFileType from 'components/files/upload/validation/match-file-type';
import { nanoid } from 'nanoid';
import { FILE_UPLOAD_MAX_SIZE } from 'env';
import { noop } from 'lodash';
import { useCallback, useState } from 'react';
import getErrorMessage from 'components/files/upload/validation/get-error-message';
import { IconFileUpload } from '@tabler/icons-react';
import type { IFile, IFileError, IFileRejectionReason } from 'components/files/upload/types';
import Toast from 'components/Toast';

/**
 * Used to collect dropped files.
 */
export default function DropFiles({
  onAccept = noop,
  onReject = noop,
  accept = ['*'],
  maxFiles = Infinity,
  maxSize = FILE_UPLOAD_MAX_SIZE,
  disabled = false,
  error = '',
  withErrorList = false,
  initialErrors = [],
}: {
  onAccept?: (files: IFile[]) => void;
  onReject?: (errors: IFileError[]) => void;
  accept?: string[];
  maxFiles?: number;
  maxSize?: number;
  disabled?: boolean;
  error?: string;
  withErrorList?: boolean;
  initialErrors?: IFileError[];
}) {
  const theme = useMantineTheme();
  const [errors, setErrors] = useState<IFileError[]>(initialErrors);

  /** Handles file selection. */
  const onDropImpl = (droppedFiles: File[] | null) => {
    if (!droppedFiles) {
      return; // User clicked on the "Cancel" button provided by the browser.
    }

    /** @type {IFile[]}      */ const valid: IFile[] = [];
    /** @type {IFileError[]} */ const errors: IFileError[] = [];

    for (const file of droppedFiles) {
      let errorReason;

      if (!matchFileType(file.type, accept)) {
        errorReason = 'accept';
      } else if (file.size > maxSize) {
        errorReason = 'size';
      } else if (valid.length >= (maxFiles as number)) {
        errorReason = 'maxFiles';
      } else {
        errorReason = null;
      }

      if (errorReason) {
        errors.push({ errorId: nanoid(), fileName: file.name, reason: errorReason as IFileRejectionReason });
      } else {
        valid.push({ uuid: nanoid(), file });
      }
    }

    onAccept(valid);
    onReject(errors);

    if (withErrorList) {
      setErrors((curr: IFileError[]) => [...curr, ...errors]);
    }
  };

  /** Closes the specified error. */
  const closeError: (error: IFileError) => void = useCallback(
    ({ errorId }: IFileError) => setErrors((errors) => errors.filter((error) => error.errorId !== errorId)),
    [setErrors]
  );

  const border = error ? 'border-complementary-danger' : 'border-nautral-300';

  return (
    <>
      <Stack spacing={16}>
        <Dropzone
          onDrop={onDropImpl}
          disabled={disabled}
          aria-disabled={disabled}
          aria-errormessage={error || undefined}
          className={`flex justify-start border border-dashed ${border}`}
        >
          <Dropzone.Accept>
            <Group w={500} position="center" spacing={8} h={72}>
              <IconFileUpload width={24} height={24} stroke={theme.fn.primaryColor()} />
              <Text fz={12} lh={16 / 12} color="primary">
                {'Pretiahnite sem súbory'}
              </Text>
            </Group>
          </Dropzone.Accept>
          <Dropzone.Idle>
            <Group position="center" spacing={16}>
              <Button variant="secondary" disabled={disabled} leftIcon={<IconFileUpload size="1.2rem" />} radius={10}>
                {'Nahrať súbory'}
              </Button>
              <Text fz={12} lh={16 / 12} color={disabled ? 'neutral300' : 'neutral700'}>
                {'Pretiahnite sem súbory alebo kliknite pre Nahratie súborov'}
              </Text>
            </Group>
          </Dropzone.Idle>
        </Dropzone>
      </Stack>

      {(withErrorList ? errors : []).map((error) => (
        <Toast
          key={error.errorId}
          type="fail"
          fullWidth
          withCloseButton
          message={getErrorMessage(error)}
          onClose={() => closeError(error)}
        />
      ))}
    </>
  );
}
