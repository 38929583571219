import { ColDef } from 'ag-grid-community';
import DeviceTypeSelectFilter from 'components/tables/filters/DeviceTypeSelectFilter';
import DepartmentSelectFilter from 'components/tables/filters/DepartmentSelectFilter';
import OrganizationSelectFilter from 'components/tables/filters/OrganizationSelectFilter';
import { useApi } from 'api/api-context';
import { forwardRef, useCallback } from 'react';
import DataTable, { IDataTablePublic } from 'components/tables/DataTable';
import DeviceSelectFilter from 'components/tables/filters/DeviceSelectFilter';
import TypeColumn from 'components/tables/data/revision-plan/columns/TypeColumn';
import DescriptionColumn from 'components/tables/data/revision-plan/columns/DescriptionColumn';
import NextRevisionColumn from 'components/tables/data/revision-plan/columns/NextRevisionColumn';
import DeviceNameColumn from 'components/tables/data/revision-plan/columns/DeviceNameColumn';
import OrganizationNameColumn from 'components/tables/data/revision-plan/columns/OrganizationNameColumn';
import DepartmentNameColumn from 'components/tables/data/revision-plan/columns/DepartmentNameColumn';
import DeviceTypeColumn from 'components/tables/data/revision-plan/columns/DeviceTypeColumn';
import ActionsColumn from 'components/tables/data/revision-plan/columns/ActionsColumn';
import ExternalIdColumn from 'components/tables/data/revision-plan/columns/ExternalIdColumn';
import { translateRevisionPeriod } from 'components/inputs/revision-period-select/RevisionPeriodSelect';

interface RevisionPlanTableProps {
  suppressSaveProfiles?: boolean;
  suppressProfileChangedNotification?: boolean;
  initialFilters?: Record<string, any>;
  afterTitle?: React.ReactNode;
}

/**
 * The columns of the revision plan table.
 */
const columns: ColDef[] = [
  {
    field: 'revisionPlanId',
    headerName: 'ID sledovania termínu',
    hide: true,
    filter: true,
  },
  {
    field: 'type',
    headerName: 'Úkon',
    minWidth: 320,
    filter: true,
    resizable: true,
    sortable: true,
    unSortIcon: true,
    wrapText: true,
    cellRenderer: TypeColumn,
  },
  {
    field: 'description',
    headerName: 'Popis',
    minWidth: 200,
    filter: true,
    resizable: true,
    wrapText: true,
    cellRenderer: DescriptionColumn,
  },
  {
    field: 'nextRevision',
    headerName: 'Najbližšia revízia',
    minWidth: 300,
    filter: true,
    resizable: true,
    sortable: true,
    cellRenderer: NextRevisionColumn,
  },
  {
    field: 'lastRevision',
    headerName: 'Posledná revízia',
    hide: true,
  },
  {
    field: '_revisionPeriodPretty',
    headerName: 'Perióda',
    hide: true,
  },
  {
    field: 'deviceId',
    headerName: 'ID zariadenia',
    hide: true,
    filter: true,
  },
  {
    field: 'deviceName',
    headerName: 'Názov zariadenia',
    minWidth: 300,
    filter: true,
    floatingFilter: true,
    floatingFilterComponent: DeviceSelectFilter,
    resizable: true,
    sortable: true,
    unSortIcon: true,
    wrapText: true,
    cellRenderer: DeviceNameColumn,
  },
  {
    field: 'organization.organizationId',
    headerName: 'ID organizácie',
    hide: true,
    filter: true,
  },
  {
    field: 'organization.organizationName',
    headerName: 'Organizácia',
    minWidth: 275,
    filter: true,
    floatingFilter: true,
    floatingFilterComponent: OrganizationSelectFilter.configure({ permissionSlug: 'browse-devices' }),
    resizable: true,
    sortable: true,
    unSortIcon: true,
    wrapText: true,
    cellRenderer: OrganizationNameColumn,
  },
  {
    field: 'department.departmentId',
    headerName: 'ID strediska',
    hide: true,
    filter: true,
  },
  {
    field: 'department.departmentName',
    headerName: 'Stredisko',
    minWidth: 275,
    filter: true,
    floatingFilter: true,
    floatingFilterComponent: DepartmentSelectFilter.configure({ permissionSlug: 'browse-devices' }),
    resizable: true,
    sortable: true,
    unSortIcon: true,
    wrapText: true,
    cellRenderer: DepartmentNameColumn,
  },
  {
    field: 'deviceType.deviceTypeId',
    hide: true,
    filter: true,
  },
  {
    field: 'deviceType.deviceTypeName',
    headerName: 'Zariadenie (termín)',
    minWidth: 220,
    filter: true,
    floatingFilter: true,
    floatingFilterComponent: DeviceTypeSelectFilter,
    resizable: true,
    sortable: true,
    unSortIcon: true,
    wrapText: true,
    cellRenderer: DeviceTypeColumn,
  },
  { field: 'device.building', headerName: 'Budova', hide: true },
  { field: 'device.floor', headerName: 'Poschodie', hide: true },
  { field: 'device.room', headerName: 'Miestnosť', hide: true },
  { field: 'device.longitude', headerName: 'Zemepisná dĺžka', hide: true },
  { field: 'device.latitude', headerName: 'Zemepisná šírka', hide: true },
  { field: 'device.responsiblePerson.fullName', headerName: 'Zodpovedná osoba', hide: true },
  {
    field: 'externalId',
    headerName: 'Externé ID',
    resizable: true,
    minWidth: 160,
    wrapText: true,
    cellRenderer: ExternalIdColumn,
  },
  {
    field: 'importId',
    headerName: 'ID importu',
    filter: true,
    minWidth: 0,
    maxWidth: 0,
    width: 0,
    cellStyle: { opacity: 0 },
  },
  {
    field: '_actions',
    headerName: '',
    pinned: 'right',
    width: 192,
    minWidth: 192,
    maxWidth: 192,
    cellRenderer: ActionsColumn,
  },
];

/**
 * Table for displaying revision plans.
 */
const RevisionPlanTable = forwardRef<IDataTablePublic, RevisionPlanTableProps>(
  ({ afterTitle, initialFilters, suppressProfileChangedNotification, suppressSaveProfiles }, ref) => {
    const { getAction } = useApi();

    const action = useCallback(async () => {
      const action = getAction('DeviceListRevisionPlan');

      const revisionPlans = await action();

      return revisionPlans.map((rp) => ({
        ...rp,
        deviceId: rp.device.deviceId,
        deviceName: rp.device.deviceName,
        manufacturer: rp.device.manufacturer,
        serialNumber: rp.device.serialNumber,
        manufactured: rp.device.manufactured,
        _revisionPeriodPretty: translateRevisionPeriod(rp.revisionPeriod),
      }));
    }, [getAction]);

    return (
      <DataTable
        ref={ref}
        tableId="revision-plans"
        title="Sledovanie termínov"
        hideToggleDiscarded
        columns={columns}
        action={action}
        initialFilters={initialFilters}
        afterTitle={afterTitle}
        suppressProfileChangedNotification={suppressProfileChangedNotification}
        suppressSaveProfiles={suppressSaveProfiles}
        dataExport={{
          modalTitle: 'Exportovať sledovanie termínov',
          fileName: 'sledovanie-termínov.xlsx',
          columnKeys: [
            'revisionPlanId',
            'type',
            'description',
            'nextRevision',
            'lastRevision',
            '_revisionPeriodPretty',
            'organization.organizationId',
            'organization.organizationName',
            'department.departmentId',
            'department.departmentName',
            'deviceId',
            'deviceName',
            'deviceType.deviceTypeName',
            'device.building',
            'device.room',
            'device.floor',
            'device.longitude',
            'device.latitude',
            'device.responsiblePerson.fullName',
            'externalId',
          ],
        }}
      />
    );
  }
);

export default RevisionPlanTable;
