import {
  IconFile,
  IconFileTypeDoc,
  IconFileTypeDocx,
  IconFileTypeJpg,
  IconFileTypePdf,
  IconFileTypePng,
  IconFileTypePpt,
  IconFileTypeTxt,
  IconFileTypeXls,
  IconFileTypeXml,
  IconFileTypeZip,
  IconGif,
} from '@tabler/icons-react';

/**
 * Maps MIME types to icons.
 */
export const fileIconMap: { [key: string]: (w: number, h: number) => JSX.Element } = {
  'application/doc': (w, h) => <IconFileTypeDoc width={w} height={h} />,
  'application/docx': (w, h) => <IconFileTypeDocx width={w} height={h} />,
  'application/pdf': (w, h) => <IconFileTypePdf width={w} height={h} />,
  'application/ppt': (w, h) => <IconFileTypePpt width={w} height={h} />,
  'application/pptx': (w, h) => <IconFileTypePpt width={w} height={h} />,
  'application/rar': (w, h) => <IconFileTypeZip width={w} height={h} />,
  'application/xls': (w, h) => <IconFileTypeXls width={w} height={h} />,
  'application/xlsx': (w, h) => <IconFileTypeXls width={w} height={h} />,
  'application/xml': (w, h) => <IconFileTypeXml width={w} height={h} />,
  'application/zip': (w, h) => <IconFileTypeZip width={w} height={h} />,
  'image/gif': (w, h) => <IconGif width={w} height={h} />,
  'image/jpeg': (w, h) => <IconFileTypeJpg width={w} height={h} />,
  'image/jpg': (w, h) => <IconFileTypeJpg width={w} height={h} />,
  'image/png': (w, h) => <IconFileTypePng width={w} height={h} />,
  'text/plain': (w, h) => <IconFileTypeTxt width={w} height={h} />,
  default: (w, h) => <IconFile width={w} height={h} />,
};

/**
 * Displays an icon for a file.
 */
export default function FileTypeIcon({ type, w = 24, h = 24 }: { type: string; w?: number; h?: number }) {
  return (fileIconMap[type] || fileIconMap.default)(w, h);
}
