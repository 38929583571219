import { useApi } from 'api/api-context';
import useImmediateAction from 'api/use-immediate-action';
import RevisionForm, { RevisionFormData } from 'components/forms/revision/RevisionForm';
import panic from 'errors/panic';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { EDIT_REVISION_PAGE_PATH, REVISIONS_PAGE_PATH } from 'routes/paths';
import { notifications } from '@mantine/notifications';
import DashboardLayout from 'layouts/dashboard/DashboardLayout';
import { IconCopy } from '@tabler/icons-react';
import { isEmpty } from 'lodash';
import { ERROR_NOTIFICATION_COLOR, WARNING_NOTIFICATION_COLOR } from 'utils/constants';
import { useEffect } from 'react';
import { REVISION_REDIRECT_AFTER_SAVE } from 'env';
import { IForm } from 'pages/revisions-module/template-editor/editors/form/types';
import {
  DeviceFields,
  RevisionFormData as NewRevisionFormData,
  RevisionDevices as NewRevisionDevices,
} from 'components/forms/revision/fill-out/types';
import { RevisionGetResponse } from 'api/actions/revision-get/revision-get-response';

export type RevisionDevices = {
  deviceId: number;
  deviceName: string;
  termId?: number;
  deviceSubtypeId: number;
  deviceSubtypeName: string;
  checked: boolean;
  manufacturer?: string;
  type: string;
  serialNumber?: string;
  manufactured?: number;
  internalPossessionsNumber?: string;
  location?: string;
  organization?: {
    organizationId: number;
    organizationName: string;
  };
}[];

/**
 * Copies the revision data based on revision's spec.
 */
export function copyRevisionData({
  oldRevision,
  newDeviceIds,
}: {
  oldRevision: RevisionGetResponse;
  newDeviceIds: number[];
}) {
  // retrieve the scheme
  const { sections }: IForm = JSON.parse(oldRevision.revisionTemplate!.formScheme!);

  // retrieve only copyable fields
  const copyableFields = sections.flatMap(({ rows }) =>
    rows.flatMap(({ inputs }) => inputs.filter(({ spec }) => spec.copyable).map(({ spec }) => spec.name))
  );

  // copy the data
  const oldData = JSON.parse(oldRevision.revisionData!) as NewRevisionFormData;

  const newFields = Object.keys(oldData.fields)
    // we want only copyable fields
    .filter((key) => copyableFields.includes(key))
    // collect their values
    .reduce((acc, key) => {
      acc[key] = oldData.fields[key];
      return acc;
    }, {} as Record<string, any>);

  const newDevices = Object.entries(oldData.devices)
    // we want only devices that are in the new revision
    .filter(([, device]) => newDeviceIds.includes(device.deviceId))
    // collect the new devices
    .reduce((acc, [key, device]) => {
      const fields = Object.keys(device.fields)
        .filter((key) => copyableFields.includes(key))
        .reduce((acc, key) => {
          acc[key] = device.fields[key];
          return acc;
        }, {} as DeviceFields);

      const measurements = Object.entries(device.measurements).reduce((acc, [key, measurement]) => {
        // collect the new measurements
        acc[key] = measurement.map((m) =>
          Object.keys(m)
            // filter out the measurements that should not be copied
            .filter((key) => key.startsWith('_') || copyableFields.includes(key))
            .reduce((acc, key) => {
              acc[key] = m[key];
              return acc;
            }, {} as Record<string, any>)
        );
        return acc;
      }, {} as Record<string, any>);

      acc[key] = {
        deviceId: device.deviceId, // ID stays the same
        fields,
        faults: device.faults, // faults stay the same
        measurements,
      };
      return acc;
    }, {} as NewRevisionDevices);

  const newData = {
    fields: newFields,
    devices: newDevices,
  };

  return newData;
}

/**
 * Page used to copy a new revision.
 *
 * - {@link https://www.figma.com/file/M2RU8Nr32l3lDgCCM3PjVL/FM-Point?node-id=256%3A14718 Figma Design}
 * - {@link N/A Notion Page}
 */
export default function CopyRevisionPage() {
  const navigate = useNavigate();
  const { getAction } = useApi();
  const { revisionId: oldRevisionId } = useParams();

  const {
    data: oldRevision,
    loading,
    error,
  } = useImmediateAction(() => {
    const action = getAction('RevisionGet');
    return action({ parameters: { revisionId: oldRevisionId! } });
  });

  useEffect(() => {
    if (oldRevision && oldRevision.devices.some((device) => !device.status)) {
      notifications.show({
        title: 'Revíznej správe sme odobrali nejaké zariadenia',
        message: 'Niektoré zariadenia pôvodnej revíznej správy sú deaktivované.',
        color: WARNING_NOTIFICATION_COLOR,
      });
    }
  }, [oldRevision]);

  if (!oldRevisionId) {
    return <Navigate to={REVISIONS_PAGE_PATH.original} />;
  }

  if (error) {
    panic(error);
    return <></>;
  }

  /**
   * Runs when the form is submitted.
   */
  async function onSubmit({
    organizationId,
    departmentId,
    revisionName,
    deadline,
    technicianId,
    devices,
  }: RevisionFormData) {
    try {
      const revisionCreate = getAction('RevisionCreate');
      let newRevisionData;

      const mappedDevices = Object.keys(devices)
        .map((deviceId) => ({
          deviceId: Number(deviceId),
          deviceName: devices[deviceId].deviceName,
          termId:
            devices[deviceId].termId && devices[deviceId].termId !== '' ? Number(devices[deviceId].termId) : undefined,
          deviceSubtypeId: devices[deviceId].deviceSubtypeId,
          deviceSubtypeName: devices[deviceId].deviceSubtypeName,
          checked: devices[deviceId].checked,
          manufacturer: devices[deviceId].manufacturer,
          type: devices[deviceId].type,
          serialNumber: devices[deviceId].serialNumber,
          manufactured: devices[deviceId].manufactured,
          internalPossessionsNumber: devices[deviceId].internalPossessionsNumber,
          location: devices[deviceId].location,
          organization: devices[deviceId].organization,
          building: devices[deviceId].building,
        }))
        .filter((device) => device.checked);

      if (oldRevision && oldRevision.revisionData) {
        const newDeviceIds = Object.keys(devices)
          .filter((deviceId) => devices[deviceId].checked)
          .map(Number);

        newRevisionData = copyRevisionData({ oldRevision, newDeviceIds });
      }

      if (isEmpty(mappedDevices)) {
        notifications.show({
          title: 'Revízna správa nemôže byť skopírovaná',
          message: 'Všetky zariadenia pôvodnej revíznej správy sú deaktivované.',
          color: ERROR_NOTIFICATION_COLOR,
        });

        return;
      }

      const copiedRevision = await revisionCreate({
        payload: {
          revisionName,
          deadline,
          organizationId: Number(organizationId),
          departmentId: Number(departmentId),
          deviceTypeId: oldRevision!.deviceType.deviceTypeId,
          devices: mappedDevices.map((device) => ({
            deviceId: device.deviceId,
            deviceSubtypeId: device.deviceSubtypeId,
            termId: device.termId,
          })),
          technicianId: Number(technicianId),
          revisionData: newRevisionData ? JSON.stringify(newRevisionData) : undefined,
          revisionTemplate: oldRevision!.revisionTemplate?.slug,
        },
      });

      notifications.show({
        title: 'Revízna správa bola skopírovaná',
        message: `Revízna správa ${
          oldRevision!.revisionName
        } bola úspešne skopírovaná a revízna správa ${revisionName} bola úspešne vytvorená.`,
        color: 'green',
      });

      if (REVISION_REDIRECT_AFTER_SAVE === 'same') {
        navigate(EDIT_REVISION_PAGE_PATH.insert({ revisionId: copiedRevision.revisionId }));
      } else if (REVISION_REDIRECT_AFTER_SAVE === 'list') {
        navigate(REVISIONS_PAGE_PATH.original);
      }
    } catch (error: any) {
      panic(error);
    }
  }

  return (
    <DashboardLayout
      breadcrumbs={[
        { title: 'Revízne správy', link: REVISIONS_PAGE_PATH.original },
        { title: 'Skopírovanie revíznej správy' },
      ]}
      title={`Skopírovanie revíznej správy: ${oldRevision?.revisionName ?? 'Načítavam...'}`}
    >
      {!loading && (
        <RevisionForm
          context="edit"
          onSubmit={onSubmit}
          initialValues={{
            revisionName: `${oldRevision.revisionName} (Kópia)`,
            deadline: '',
            organizationId: String(oldRevision.organization.organizationId),
            departmentId: String(oldRevision.department.departmentId),
            deviceTypeId: String(oldRevision.deviceType.deviceTypeId),
            devices: oldRevision.devices.reduce(
              (acc, item) => {
                acc[String(item.deviceId)] = {
                  deviceName: item.deviceName,
                  termId: item.termId ? String(item.termId) : '',
                  deviceSubtypeId: item.deviceSubtype?.deviceTypeId ?? 0,
                  deviceSubtypeName: item.deviceSubtype?.deviceTypeName ?? '',
                  checked: item.status,
                  manufacturer: item.manufacturer,
                  type: item.type,
                  serialNumber: item.serialNumber,
                  manufactured: item.manufactured,
                  internalPossessionsNumber: item.internalPossessionsNumber ?? '',
                  location: item.location ?? '',
                  building: item.building ?? '',
                  organization: item.organization
                    ? {
                        organizationId: item.organization.organizationId,
                        organizationName: item.organization.organizationName,
                      }
                    : undefined,
                  revisionPlan: (item.revisionPlan || []).map((rp) => ({
                    revisionPlanId: rp.revisionPlanId!,
                    description: rp.description,
                    date: rp.date,
                    revisionPeriod: rp.revisionPeriod,
                    neverExecuted: rp.neverExecuted,
                  })),
                };
                return acc;
              },
              {} as {
                [deviceId: string]: {
                  deviceName: string;
                  termId: string;
                  deviceSubtypeId: number;
                  deviceSubtypeName: string;
                  checked: boolean;
                  manufacturer?: string;
                  type: string;
                  serialNumber?: string;
                  manufactured?: number;
                  internalPossessionsNumber?: string;
                  building?: string;
                  location?: string;
                  organization?: {
                    organizationId: number;
                    organizationName: string;
                  };
                  revisionPlan?: {
                    revisionPlanId: number;
                    description: string;
                    date?: string;
                    revisionPeriod: number;
                    neverExecuted: boolean;
                  }[];
                };
              }
            ),
            technicianId: String(oldRevision.technician.userId),
          }}
          primaryButtonText="Skopírovať"
          primaryIcon={<IconCopy stroke={1.5} size={24} />}
          readOnly={{
            deviceTypeId: true,
          }}
          noValueChange={{
            organizationId: true,
            departmentId: true,
            deviceTypeId: true,
            technicianId: true,
          }}
        />
      )}
    </DashboardLayout>
  );
}
