import { ColDef, GridApi } from 'ag-grid-community';
import { createContext, forwardRef, useCallback, useContext, useMemo, useState } from 'react';
import DataTable, { IDataTablePublic } from 'components/tables/DataTable';
import { ADD_REVISION_PAGE_PATH } from 'routes/paths';
import OrganizationSelectFilter from 'components/tables/filters/OrganizationSelectFilter';
import DepartmentSelectFilter from 'components/tables/filters/DepartmentSelectFilter';
import DeviceTypeSelectFilter from 'components/tables/filters/DeviceTypeSelectFilter';
import RevisionStatusFloatingFilter from 'components/tables/filters/revision-status/RevisionStatusFloatingFilter';
import { useApi } from 'api/api-context';
import DeviceSubtypeContainsSelectFilter from 'components/tables/filters/device-subtype/DeviceSubtypeContainsSelectFilter';
import AssignedBySelectFilter from 'components/tables/filters/AssignedBySelectFilter';
import TechnicianSelectFilter from 'components/tables/filters/TechnicianSelectFilter';
import RevisionDeadlineSelectFilter from 'components/tables/filters/RevisionDeadlineSelectFilter';
import { isAfter } from 'date-fns';
import { noop } from 'lodash';
import RevisionStatusFilter from 'components/tables/filters/revision-status/RevisionStatusFilter';
import OrganizationNameColumn from 'components/tables/data/revision/columns/OrganizationNameColumn';
import RevisionIdColumn from 'components/tables/data/revision/columns/RevisionIdColumn';
import DepartmentNameColumn from 'components/tables/data/revision/columns/DepartmentNameColumn';
import AssignedByOrganizationNameColumn from 'components/tables/data/revision/columns/AssignedByOrganizationNameColumn';
import DeviceTypeNameColumn from 'components/tables/data/revision/columns/DeviceTypeNameColumn';
import DeviceSubtypesColumn from 'components/tables/data/revision/columns/DeviceSubtypesColumn';
import RevisionStatusColumn from 'components/tables/data/revision/columns/RevisionStatusColumn';
import RevisionNameColumn from 'components/tables/data/revision/columns/RevisionNameColumn';
import TechnicianNameColumn from 'components/tables/data/revision/columns/TechnicianNameColumn';
import DeadlineColumn from 'components/tables/data/revision/columns/DeadlineColumn';
import DevicesColumn from 'components/tables/data/revision/columns/DevicesColumn';
import ActionsColumn from 'components/tables/data/revision/columns/ActionsColumn';
import { OnStatusChangeParams, RevisionRow } from 'components/tables/data/revision/types';
import SafetyPointColumn from 'components/tables/data/revision/columns/SafetyPointColumn';
import RevisionSafetyPointSelectFilter from 'components/tables/filters/RevisionSafetyPointSelectFilter';
import { Group } from '@mantine/core';
import BulkAcceptAction from 'components/tables/data/revision/actions/bulk/BulkAcceptAction';
import BulkApproveAction from 'components/tables/data/revision/actions/bulk/BulkApproveAction';
import BulkConfirmAction from 'components/tables/data/revision/actions/bulk/BulkConfirmAction';
import BulkDeleteAction from 'components/tables/data/revision/actions/bulk/BulkDeleteAction';
import FinishedAtColumn from 'components/tables/data/revision/columns/FinishedAtColumn';
import NextRevisionDateColumn from 'components/tables/data/revision/columns/NextRevisionDateColumn';
import DocumentValidUntilColumn from 'components/tables/data/revision/columns/DocumentValidUntilColumn';
import RevisionDeviceSelectFilter from 'components/tables/filters/RevisionDeviceSelectFilter';
import RevisionTemplateColumn from 'components/tables/data/revision/columns/RevisionTemplateColumn';
import RevisionTemplateSelectFilter from 'components/tables/filters/RevisionTemplateSelectFilter';

interface RevisionTableProps {
  suppressSaveProfiles?: boolean;
  suppressProfileChangedNotification?: boolean;
  initialFilters?: Record<string, any>;
}

/**
 * The columns of the table.
 */
const columns: ColDef[] = [
  {
    field: 'revisionId',
    headerName: 'ID',
    sortable: true,
    unSortIcon: true,
    width: 72,
    minWidth: 72,
    maxWidth: 72,
    cellRenderer: RevisionIdColumn,
  },
  { field: 'organization.organizationId', hide: true, filter: true, headerName: 'ID organizácie' },
  {
    field: 'organization.organizationName',
    headerName: 'Organizácia',
    minWidth: 225,
    filter: true,
    floatingFilter: true,
    floatingFilterComponent: OrganizationSelectFilter,
    resizable: true,
    sortable: true,
    unSortIcon: true,
    wrapText: true,
    cellRenderer: OrganizationNameColumn,
  },
  { field: 'department.departmentId', hide: true, filter: true, headerName: 'ID strediska' },
  {
    field: 'department.departmentName',
    headerName: 'Stredisko',
    minWidth: 250,
    filter: true,
    floatingFilter: true,
    floatingFilterComponent: DepartmentSelectFilter,
    resizable: true,
    sortable: true,
    unSortIcon: true,
    wrapText: true,
    cellRenderer: DepartmentNameColumn,
  },
  {
    field: 'assignedBy.organizationId',
    hide: true,
    filter: true,
    headerName: 'ID organizácie zadávateľa',
  },
  {
    field: 'assignedBy.organizationName',
    headerName: 'Zadávateľ',
    minWidth: 200,
    filter: true,
    floatingFilter: true,
    floatingFilterComponent: AssignedBySelectFilter,
    resizable: true,
    sortable: true,
    unSortIcon: true,
    wrapText: true,
    cellRenderer: AssignedByOrganizationNameColumn,
  },
  {
    field: 'deviceType.deviceTypeId',
    hide: true,
    filter: true,
    headerName: 'ID typu zariadenia',
  },
  {
    field: 'deviceType.deviceTypeName',
    headerName: 'Zariadenie',
    minWidth: 220,
    filter: true,
    floatingFilter: true,
    floatingFilterComponent: DeviceTypeSelectFilter,
    resizable: true,
    sortable: true,
    unSortIcon: true,
    wrapText: true,
    cellRenderer: DeviceTypeNameColumn,
  },
  {
    field: 'deviceSubtypes.deviceTypeId',
    hide: true,
    filter: true,
  },
  {
    field: 'deviceSubtypes.deviceTypeName',
    hide: true,
    filter: true,
  },
  {
    field: 'deviceSubtypesString',
    hide: true,
    headerName: 'Typ zariadenia',
  },
  {
    field: 'deviceSubtypes',
    headerName: 'Typ zariadenia',
    minWidth: 220,
    filter: true,
    floatingFilter: true,
    floatingFilterComponent: DeviceSubtypeContainsSelectFilter,
    resizable: true,
    sortable: true,
    unSortIcon: true,
    wrapText: true,
    cellRenderer: DeviceSubtypesColumn,
  },
  {
    field: 'revisionStatus.revisionStatusName',
    headerName: 'Stav revíznej správy',
    filter: true,
    minWidth: 0,
    maxWidth: 0,
    width: 0,
    cellRenderer: noop,
  },
  {
    field: 'revisionStatus.revisionStatusId',
    headerName: 'Stav',
    minWidth: 180,
    resizable: true,
    wrapText: true,
    sortable: true,
    unSortIcon: true,
    filter: RevisionStatusFilter,
    floatingFilter: true,
    floatingFilterComponent: RevisionStatusFloatingFilter,
    cellRenderer: RevisionStatusColumn,
  },
  {
    field: 'revisionName',
    headerName: 'Názov',
    minWidth: 250,
    sortable: true,
    resizable: true,
    unSortIcon: true,
    wrapText: true,
    cellRenderer: RevisionNameColumn,
  },
  {
    field: 'revisionTemplate.slug',
    headerName: 'Šablóna',
    minWidth: 250,
    sortable: true,
    resizable: true,
    unSortIcon: true,
    wrapText: true,
    filter: true,
    floatingFilter: true,
    floatingFilterComponent: RevisionTemplateSelectFilter,
    cellRenderer: RevisionTemplateColumn,
  },
  {
    field: 'technician.userId',
    hide: true,
    filter: true,
    headerName: 'ID revízneho technika',
  },
  {
    field: 'technician.fullName',
    headerName: 'Revízny technik',
    minWidth: 180,
    sortable: true,
    resizable: true,
    unSortIcon: true,
    wrapText: true,
    filter: true,
    floatingFilter: true,
    floatingFilterComponent: TechnicianSelectFilter,
    cellRenderer: TechnicianNameColumn,
  },
  {
    field: 'deadlineStatus',
    hide: true,
    filter: true,
  },
  {
    field: 'deadline',
    headerName: 'Deadline',
    resizable: true,
    minWidth: 170,
    sortable: true,
    unSortIcon: true,
    filter: true,
    floatingFilter: true,
    floatingFilterComponent: RevisionDeadlineSelectFilter,
    cellRenderer: DeadlineColumn,
  },
  {
    field: 'safetyPoint',
    hide: true,
    filter: true,
  },
  {
    field: 'safetyPointPretty',
    headerName: 'Záver',
    resizable: true,
    minWidth: 180,
    sortable: true,
    unSortIcon: true,
    filter: true,
    floatingFilter: true,
    wrapText: true,
    floatingFilterComponent: RevisionSafetyPointSelectFilter,
    cellRenderer: SafetyPointColumn,
  },
  {
    field: 'finishedAt',
    headerName: 'Dátum ukončenia',
    resizable: true,
    minWidth: 200,
    sortable: true,
    unSortIcon: true,
    cellRenderer: FinishedAtColumn,
  },
  {
    field: 'nextRevisionDate',
    headerName: 'Dátum nasledujúcej revíznej správy',
    wrapHeaderText: true,
    resizable: true,
    minWidth: 200,
    sortable: true,
    unSortIcon: true,
    cellRenderer: NextRevisionDateColumn,
  },
  {
    field: 'documentValidUntil',
    headerName: 'Deadline na prepečiatkovanie',
    wrapHeaderText: true,
    resizable: true,
    minWidth: 200,
    sortable: true,
    unSortIcon: true,
    cellRenderer: DocumentValidUntilColumn,
  },
  {
    field: 'kepMessageUuid',
    headerName: 'NFQES UUID',
    hide: true,
  },
  {
    field: 'signedFileName',
    headerName: 'Podpísaný súbor',
    hide: true,
  },
  {
    field: '_search.deviceId',
    hide: true,
    filter: true,
  },
  {
    field: '_search.deviceName',
    headerName: 'Názov zariadenia',
    resizable: true,
    minWidth: 250,
    filter: true,
    floatingFilter: true,
    floatingFilterComponent: RevisionDeviceSelectFilter,
    wrapText: true,
    cellStyle: { display: 'flex' },
    cellRenderer: DevicesColumn,
  },
  {
    field: '_actions',
    headerName: '',
    width: 260,
    minWidth: 260,
    maxWidth: 260,
    pinned: 'right',
    cellStyle: { display: 'flex' },
    cellRenderer: ActionsColumn,
  },
];

/**
 * Translates the safety point to a human readable string.
 */
function translateSafetyPoint(safetyPoint: string | undefined) {
  switch (safetyPoint) {
    case 'suitable':
      return 'Vyhovuje';
    case 'not-suitable':
      return 'Nevyhovuje';
    case 'with-reservations':
      return 'Vyhovuje s výhradami';
    default:
      return '-';
  }
}

/**
 * Table which displays revisions.
 */
const RevisionTableImpl = forwardRef<IDataTablePublic, RevisionTableProps>(
  ({ initialFilters, suppressProfileChangedNotification, suppressSaveProfiles }, ref) => {
    const { getAction, hasPermissionAnywhere } = useApi();
    const [selectedRows, setSelectedRows] = useState<RevisionRow[]>([]);
    const [api, setApi] = useState<GridApi<RevisionRow> | null>(null);

    const [defaultProfileFilters] = useState(() => ({
      'revisionStatus.revisionStatusId': {
        filter: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      },
    }));

    const action = useCallback(async () => {
      const action = getAction('RevisionList');

      const revisions = await action();

      return revisions.map((revision) => ({
        ...revision,
        deviceSubtypes: {
          deviceTypeId: revision.deviceSubtype.map((deviceSubtype) => deviceSubtype.deviceTypeId).join(', '),
          deviceTypeName: revision.deviceSubtype.map((deviceSubtype) => deviceSubtype.deviceTypeName).join(', '),
        },
        deadlineStatus: isAfter(new Date(revision.deadline), new Date()) ? 'before-deadline' : 'after-deadline',
        safetyPointPretty: translateSafetyPoint(revision.safetyPoint),
        _search: {
          deviceName: revision.devices.map(({ deviceName }) => deviceName).join(', '),
          deviceId: revision.devices.map(({ deviceId }) => deviceId).join(', '),
        },
        deviceSubtypesString: revision.deviceSubtype.map((deviceSubtype) => deviceSubtype.deviceTypeName).join(', '),
      }));
    }, [getAction]);

    /**
     * Handles the status change of a revision.
     */
    const onStatusChange = useCallback(
      ({ revisionId, status }: OnStatusChangeParams) => {
        const row = api?.getRowNode(String(revisionId));

        if (row && row.data) {
          row.setData({ ...row.data, revisionStatus: status });
        }
      },
      [api]
    );

    return (
      <DataTable
        ref={ref}
        tableId="revisions"
        title="Revízne správy"
        addButtonText="Pridať novú"
        hideToggleDiscarded
        getRowId={({ data: { revisionId } }) => String(revisionId)}
        columns={columns}
        action={action}
        addButtonTarget={ADD_REVISION_PAGE_PATH.original}
        initialFilters={initialFilters}
        defaultProfileFilters={defaultProfileFilters}
        hideAddButton={!hasPermissionAnywhere('assign-revisions')}
        selectedRows={selectedRows}
        onSelectedRowsChange={setSelectedRows}
        onReady={setApi}
        suppressSaveProfiles={suppressSaveProfiles}
        suppressProfileChangedNotification={suppressProfileChangedNotification}
        bulkActions={
          <Group spacing={32}>
            <BulkAcceptAction
              revisions={selectedRows}
              onStatusChange={onStatusChange}
              onBulkActionComplete={() => api?.deselectAll()}
            />
            <BulkApproveAction
              revisions={selectedRows}
              onStatusChange={onStatusChange}
              onBulkActionComplete={() => api?.deselectAll()}
            />
            <BulkConfirmAction
              revisions={selectedRows}
              onStatusChange={onStatusChange}
              onBulkActionComplete={() => api?.deselectAll()}
            />
            <BulkDeleteAction
              revisions={selectedRows}
              onStatusChange={onStatusChange}
              onBulkActionComplete={() => api?.deselectAll()}
            />
          </Group>
        }
        dataExport={{
          modalTitle: 'Exportovať revízne správy',
          fileName: 'revizne-spravy.xlsx',
          columnKeys: [
            'revisionId',
            'organization.organizationId',
            'organization.organizationName',
            'department.departmentId',
            'department.departmentName',
            'assignedBy.organizationId',
            'assignedBy.organizationName',
            'deviceType.deviceTypeId',
            'deviceType.deviceTypeName',
            'deviceSubtypesString',
            'revisionStatus.revisionStatusName',
            'revisionName',
            'technician.userId',
            'technician.fullName',
            'deadline',
            'safetyPointPretty',
            'finishedAt',
            'nextRevisionDate',
            'documentValidUntil',
            'kepMessageUuid',
            'signedFileName',
            '_search.deviceName',
          ],
        }}
      />
    );
  }
);

interface IRevisionTableContext {}

const RevisionTableContext = createContext<IRevisionTableContext>(undefined!);

/**
 * Table which displays revisions.
 */
const RevisionTable = forwardRef<IDataTablePublic, RevisionTableProps>((props, ref) => {
  const value = useMemo(() => ({}), []);

  return (
    <RevisionTableContext.Provider value={value}>
      <RevisionTableImpl ref={ref} {...props} />
    </RevisionTableContext.Provider>
  );
});

export default RevisionTable;

/**
 * Hook to access the revision table context.
 */
export function useRevisionTableContext() {
  return useContext(RevisionTableContext);
}
