import { useCallback, useMemo, useState } from 'react';
import { useFileManager } from 'api/file-manager/file-manager-context';
import panic from 'errors/panic';

/**
 * Triggers file download in the browser.
 */
function downloadFile(url: string, filename: string) {
  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  a.click();
}

/**
 * Hook for downloading a file.
 */
export default function useFileDownload() {
  const { getFileMetadata, createAccessToken } = useFileManager();

  const [loading, setLoading] = useState(false);

  /**
   * Downloads a single file.
   */
  const downloadSingle = useCallback(
    async (fileId: string) => {
      const [accessToken, { basename }] = await Promise.all([
        createAccessToken({ fileId }),
        getFileMetadata({ fileId }),
      ]);

      const url = `/api/files/one-time/file/${accessToken}/${encodeURIComponent(basename)}`;

      downloadFile(url, basename);
    },
    [getFileMetadata, createAccessToken]
  );

  /**
   * Downloads all files.
   */
  const download = useCallback(
    (fileId: string | string[]) => {
      return new Promise<void>((resolve) => {
        setLoading((loading) => {
          if (loading) {
            return loading;
          }

          const files = Array.isArray(fileId) ? fileId : [fileId];

          Promise.all(files.map((fileId) => downloadSingle(fileId)))
            .then(() => resolve())
            .catch(panic)
            .finally(() => setLoading(false));

          return true;
        });
      });
    },
    [setLoading, downloadSingle]
  );

  return useMemo(() => ({ download, loading }), [download, loading]);
}
