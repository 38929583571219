import DashboardLayout from 'layouts/dashboard/DashboardLayout';
import DepartmentTable from 'components/tables/data/department/DepartmentTable';
import { useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

/**
 * Page used to list departments.
 *
 * - {@link https://www.figma.com/file/M2RU8Nr32l3lDgCCM3PjVL/FM-Point?node-id=250%3A6994 Figma Design}
 * - {@link https://www.notion.so/Administrative-List-Departments-6f17d8fa9c2b4bbd929ea1b4e94b297d?pvs=4 Notion Page}
 */
export default function DepartmentsPage() {
  const [searchParams, setSearchParams] = useSearchParams();

  const [initialFilters] = useState(() => {
    const filters: Record<string, any> = {};

    if (searchParams.has('organizationId')) {
      filters['organization.organizationId'] = {
        filterType: 'text',
        type: 'equals',
        filter: searchParams.get('organizationId'),
      };
    }

    return filters;
  });

  useEffect(() => {
    setSearchParams(
      (params) => {
        params.delete('organizationId');
        return params;
      },
      { replace: true }
    );
  }, []);

  return (
    <DashboardLayout breadcrumbs={[{ title: 'Strediská' }]} height="screen">
      <DepartmentTable initialFilters={initialFilters} />
    </DashboardLayout>
  );
}
