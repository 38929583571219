import { ActionIcon, Button, Group, Menu } from '@mantine/core';
import { IconDotsVertical, IconDownload, IconTrashX } from '@tabler/icons-react';
import { useApi } from 'api/api-context';
import useFileDownload from 'api/use-file-download';
import { useConfirm } from 'components/modals/message/MessageProvider';
import { noop } from 'lodash';
import { useCallback } from 'react';
import panic from 'errors/panic';

interface SystemExportActionsProps {
  systemExportId: number;
  fileId: string;
  onDelete?: () => void;
}

/**
 * Actions for the system export table.
 */
export default function SystemExportActions({ systemExportId, fileId, onDelete = noop }: SystemExportActionsProps) {
  const { getAction } = useApi();
  const { confirm } = useConfirm();
  const { download, loading } = useFileDownload();

  const confirmDelete = useCallback(() => {
    const deleteSystemExport = getAction('SystemExportDelete');

    confirm({
      title: 'Zmazať export',
      content: 'Naozaj chcete zmazať tento export? Táto akcia je nevratná.',
      onConfirm: () =>
        deleteSystemExport({ parameters: { systemExportId: String(systemExportId) } })
          .then(onDelete)
          .catch(panic),
    });
  }, [confirm, getAction, systemExportId, onDelete]);

  return (
    <Group spacing={12} noWrap>
      <Button
        size="md"
        variant="secondary"
        leftIcon={<IconDownload />}
        onClick={() => download(fileId)}
        loading={loading}
      >
        Stiahnuť
      </Button>
      <Menu position="bottom-end" withinPortal>
        <Menu.Target>
          <ActionIcon variant="tertiary" size="md">
            <IconDotsVertical stroke="1.5" height={24} width={24} />
          </ActionIcon>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item color="red.8" icon={<IconTrashX stroke={1.5} size={20} />} onClick={confirmDelete}>
            Zmazať
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </Group>
  );
}
