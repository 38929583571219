import { Group } from '@mantine/core';
import { RevisionListResponse } from 'api/actions/revision-list/revision-list-response';
import { useApi } from 'api/api-context';
import { DataSelect, DataSelectInheritedProps } from 'components/selects/DataSelect';
import P4Medium from 'components/typography/P4Medium';
import { forwardRef, useCallback } from 'react';

interface RevisionSelectProps extends DataSelectInheritedProps {
  showDeleted?: boolean;
  templateSlug?: string;
  excludeRevisionId?: number;
  excludeRevisionsWithoutData?: boolean;
}

interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
  selected: boolean;
  item?: RevisionListResponse[number];
  label: string;
}

const SelectItem = forwardRef<HTMLDivElement, ItemProps>(({ label, item: revision, selected, ...others }, ref) => {
  return (
    <div ref={ref} {...others}>
      {revision ? (
        <Group spacing={8} noWrap className="grid grid-cols-[auto,1fr]">
          <P4Medium color={selected ? 'gray.1' : 'gray.6'}>(ID: {revision.revisionId})</P4Medium>
          <span>{revision.revisionName}</span>
        </Group>
      ) : (
        <span>{label}</span>
      )}
    </div>
  );
});

/**
 * Select component for selecting revisions.
 */
export default function RevisionSelect({
  showDeleted = false,
  templateSlug,
  excludeRevisionId,
  excludeRevisionsWithoutData = false,
  value,
  ...props
}: RevisionSelectProps) {
  const { getAction } = useApi();

  const action = useCallback(async () => {
    const getRevisions = getAction('RevisionList');

    const revisionStatusIds = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

    if (showDeleted) {
      revisionStatusIds.push(999);
    }

    const filters: Record<string, any> = {
      'statusId.in': revisionStatusIds,
      'templateSlug.eq': templateSlug,
    };

    if (excludeRevisionsWithoutData) {
      filters['revisionData.isNotNull'] = true;
    }

    const revisions = await getRevisions({ query: { filters } });

    return revisions
      .filter(({ revisionId }) => revisionId !== excludeRevisionId)
      .map((revision) => ({
        ...revision,
        _value: revision.revisionId,
        _label: `(ID: ${revision.revisionId}) ${revision.revisionName}`,
        _group: revision.department.departmentName,
      }));
  }, [getAction, showDeleted, templateSlug, excludeRevisionId, excludeRevisionsWithoutData]);

  return (
    <DataSelect
      value={value}
      action={action}
      labelProp="_label"
      valueProp="_value"
      groupProp="_group"
      itemComponent={SelectItem}
      {...props}
    />
  );
}
